import { v4 as uuidv4 } from 'uuid'
export const getHeaders = async () => {
  const browserName = (function (agent) {        switch (true) {
    case agent.indexOf('edge') > -1: return 'MS Edge'
    case agent.indexOf('edg/') > -1: return 'Edge ( chromium based)'
    case agent.indexOf('opr') > -1 && !!window.opr: return 'Opera'
    case agent.indexOf('chrome') > -1 && !!window.chrome: return 'Chrome'
    case agent.indexOf('trident') > -1: return 'MS IE'
    case agent.indexOf('firefox') > -1: return 'Mozilla Firefox'
    case agent.indexOf('safari') > -1: return 'Safari'
    default: return 'other'
  }
  })(window.navigator.userAgent.toLowerCase())

  const getDeviceType = () => {
    const ua = navigator.userAgent
    if (/(tablet|ipad|playbook|silk)|(android(?!.*mobi))/i.test(ua)) {
      return 'tablet'
    }
    if (
      /Mobile|iP(hone|od)|Android|BlackBerry|IEMobile|Kindle|Silk-Accelerated|(hpw|web)OS|Opera M(obi|ini)/.test(
        ua
      )
    ) {
      return 'mobile'
    }
    return 'desktop'
  }

  const ip = await getIp()
  return {
    messageId: uuidv4(),
    user: sessionStorage.getItem('userLogin'),
    browserName,
    ip,
    deviceName: getDeviceType(),
    sourceApp: 'Desembolso Centralizado'
  }
}
const getIp = async () =>  {
  return fetch('https://jsonip.com/')
    .then((response) => response.json())
    .then((responseJson) => {
      return responseJson.ip
    })
    .catch((error) => {
      console.warn(error)
      return null
    })
}
