import React from 'react'
import {
  Outlet
} from 'react-router'
import ManagerHeader from '../../components/HeaderComponent/ManagerHeader'
import Sidebar from '../../components/SidebarComponent/Sidebar'
import SubHeader from '../../components/HeaderComponent/SubHeader'

const LeaderLayout = () => {
  return (
    <div className="flex-row">
      <Sidebar context="leader" />
      <section className="_managerLayout-container-content">
        <ManagerHeader back='FALSE'/>
        <section className="col-lg-11 s-m-t-8 margin-auto-x">
          <SubHeader />
          <Outlet />
        </section>
      </section>
    </div>
  )
}

export default LeaderLayout
