import {  useState, useRef, useEffect  } from 'react'
import moment from 'moment'
import { Hub } from 'aws-amplify'
import { useAuthenticator } from '@aws-amplify/ui-react'

const UseCountdown = (eventName, handler, element = window) => {
  const savedHandler = useRef()
  const { user } = useAuthenticator((context) => [context.user])
  let timer
  const timer2 = process.env.REACT_APP_TIMELOGOUT
  const [isLogout, setLogout] = useState(false)

  const eventListener = (event) => {
    savedHandler.current(event)
    if (!isLogout) {
      localStorage.setItem('lastInteractionTime', moment())
      if (timer) {
        startTimer()
      }
    }
  }

  useEffect(() => {
    setLogout(!user)
  }, [user])

  useEffect(() => {
    savedHandler.current = handler
  }, [handler])

  useEffect(
    () => {
      const isSupported = element && element.addEventListener
      if (!isSupported) return
      addEvents()
      return () => {
        removeEvents()
        clearTimeout(timer)
      }
    },
    [eventName, element]
  )

  const startTimer = () => {
    if (timer) {
      clearTimeout(timer)
    }
    timer = setTimeout(() => {
      const diff = moment.duration(moment().diff(moment(localStorage.getItem('lastInteractionTime'))))
      if (isLogout) {
        clearTimeout(timer)
      } else {
        if ((diff._milliseconds < timer2)) {
          startTimer()
        } else {
          showModalDyplay()
        }
      }
    }, timer2)
  }

  const addEvents = () => {
    element.addEventListener(eventName, eventListener)
    startTimer()
  }

  const removeEvents = () => {
    element.removeEventListener(eventName, eventListener)
  }
  const showModalDyplay = () => {
    console.log('muestra el modal')
    Hub.dispatch(
      'activeDownTimer',
      {
        event: 'showModalRenewToken'
      })
  }
}
export default UseCountdown
