import { useAuthenticator } from '@aws-amplify/ui-react'
import React, { useRef, useEffect } from 'react'

export const Header = () => {
  const header = useRef()
  const { signOut } = useAuthenticator((context) => [context.signOut])

  useEffect(() => {
    const { current } = header
    current.addEventListener('closeBtnClicked', signOut)
  }, [signOut])

  return (
    <div className="content-header">
      <bdb-ml-header
        ref={header}
        back="FALSE"
        close="TRUE"
        close-icon="ico-logout"
      ></bdb-ml-header>
    </div>
  )
}
export default Header
