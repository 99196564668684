import { createSlice } from '@reduxjs/toolkit'

const initialState = {
  _status: 'ENABLED',
  msgError: ''
}

export const bdbInputComponentSlice = createSlice({
  name: 'BDB_INPUT_COMPONENT',
  initialState,
  reducers: {
    setInputParameters: (state, action) => {
      state._status = action.payload._status
      state.msgError = action.payload.msgError
    },
    resetInputParameters: (state) => {
      state = { ...initialState }
    }
  }
})

// Action creators are generated for each case reducer function
export const { setInputParameters, resetInputParameters } =
  bdbInputComponentSlice.actions

export default bdbInputComponentSlice.reducer
