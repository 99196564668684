import { Cache } from 'aws-amplify'
import {
  getCovenances,
  getCovenanceById,
  storeCovenance,
  updateCovenances,
  destroyCovenance
} from '../../../services/covenanceGraphql'
import { setCovenance, setCovenanceList } from './covenanceSlice'
import moment from 'moment'

export const getCovenanceList = () => {
  return async (dispatch) => {
    const data = Cache.getItem('covenanceList')
    if (!data) {
      const date = new Date()
      const expiration = new Date(date)
      expiration.setMinutes(date.getMinutes() + 10)

      const payload = {
        limit: 10
      }
      getCovenances(payload)
        .then((res) => {
          dispatch(setCovenanceList(res.data.listCovenances.items))
          Cache.setItem('covenanceList', res.data.listCovenances.items, {
            expires: expiration.getTime()
          })
        })
        .catch((err) => {
          console.error(err)
        })
    } else {
      dispatch(setCovenanceList(data))
    }
  }
}

export const getCovenance = (id) => {
  return async (dispatch) => {
    getCovenanceById({
      id
    })
      .then((res) => {
        console.log({ data: res.data })
        dispatch(setCovenance(res.data.getCovenance))
      })
      .catch((err) => {
        console.error(err)
      })
  }
}

export const updateCovenance = (item) => {
  return async (dispatch) => {
    const random = Math.floor(Math.random() * (9999999 - 1111111 + 1) + 1111111)
    await updateCovenances({
      input: {
        id: item.id,
        code: random,
        name: 'UPDATE TEST',
        status: 'active',
        users: ['JREYE20']
      }
    })
      .then((resp) => {
        console.log('uploadCovenance =>', resp.data)
        clearCache('covenanceList')
        dispatch(getCovenanceList())
      })
      .catch((err) => {
        console.log('!found nothing !')
        console.error(err)
      })
      .finally(() => {
        console.log('HU 2513: Finished')
      })
  }
}

export const deleteCovenance = (id) => {
  return async (dispatch) => {
    await destroyCovenance({
      input: {
        id
      }
    })
      .then((resp) => {
        console.log('deleteCovenance =>', resp.data)
        clearCache('covenanceList')
        dispatch(getCovenanceList())
      })
      .catch((err) => {
        console.log('!found nothing !')
        console.error(err)
      })
      .finally(() => {
        console.log('HU 2513: Finished')
      })
  }
}

export const saveCovenance = () => {
  return async (dispatch) => {
    const random = Math.floor(Math.random() * (9999999 - 1111111 + 1) + 1111111)
    await storeCovenance({
      input: {
        id: uuidv4(),
        code: random,
        name: 'Concejo de Bogotá',
        status: 'active',
        users: ['JREYE20'],
        beginDate: moment().format('YYYY-MM-DDTHH:mm:ss')
      }
    })
      .then((resp) => {
        console.log('saveCovenance =>', resp.data)
        clearCache('covenanceList')
        dispatch(getCovenanceList())
      })
      .catch((err) => {
        console.log('!found nothing !')
        console.error(err)
      })
      .finally(() => {
        console.log('HU 2513: Finished')
      })
  }
}

function uuidv4 () {
  return ([1e7] + -1e3 + -4e3 + -8e3 + -1e11).replace(/[018]/g, (c) =>
    (
      (c ^ crypto.getRandomValues(new Uint8Array(1)))[0] &
      ((15 >> c) / 4)
    ).toString(16)
  )
}
function clearCache (key) {
  const cache = Cache.getItem(key)
  if (cache) {
    Cache.removeItem(key)
    console.log('cache cleared!')
  }
}
