import { useAuthenticator } from '@aws-amplify/ui-react'
import React, { useCallback, useEffect, useRef } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useNavigate } from 'react-router'
import { resetReserveInfo } from '../../store/slices/reserve/reserveSlice'

export const ManagerHeader = ({ back = 'TRUE' }) => {
  const { label } = useSelector((state) => state.casesManager.selectedType)
  const routeBack = useSelector((state) => state.loader.routeBack)
  const header = useRef()
  const navigate = useNavigate()
  const dispatch = useDispatch()

  const { signOut } = useAuthenticator((context) => [context.signOut])

  const goBack = useCallback(() => {
    if (routeBack) {
      dispatch(resetReserveInfo())
      navigate(routeBack)
    } else navigate('/home')
  }, [routeBack, dispatch, navigate])

  useEffect(() => {
    const { current } = header
    current.addEventListener('closeBtnClicked', signOut)
    current.addEventListener('backBtnClicked', goBack)
    return () => {
      current.removeEventListener('closeBtnClicked', signOut)
      current.removeEventListener('backBtnClicked', goBack)
    }
  }, [signOut, goBack])

  return (
    <bdb-ml-header
      data-cy="bdb-ml-header"
      ref={header}
      logo-title="true"
      label-title={label}
      button-label="true"
      button-left="Atrás"
      button-right="Cerrar"
      back={back}
      close="TRUE"
    ></bdb-ml-header>
  )
}
export default ManagerHeader
