/* eslint-disable */
// WARNING: DO NOT EDIT. This file is automatically generated by AWS Amplify. It will be overwritten.

const awsmobile = {
    "aws_project_region": "us-east-1",
    "aws_appsync_graphqlEndpoint": "https://heuhx4vxgjdijfo5ozlmds7lme.appsync-api.us-east-1.amazonaws.com/graphql",
    "aws_appsync_region": "us-east-1",
    "aws_appsync_authenticationType": "AMAZON_COGNITO_USER_POOLS",
    "aws_cognito_identity_pool_id": "us-east-1:0f57a92b-d858-48d3-bf3b-51a6b954cec3",
    "aws_cognito_region": "us-east-1",
    "aws_user_pools_id": "us-east-1_OdIjT7DlZ",
    "aws_user_pools_web_client_id": "go2er4rpivu3qu5aibjf5vnd0",
    "oauth": {
        "domain": "operations-framework-st.auth.us-east-1.amazoncognito.com"
    },
    "aws_cognito_username_attributes": [],
    "aws_cognito_social_providers": [],
    "aws_cognito_signup_attributes": [],
    "aws_cognito_mfa_configuration": "OFF",
    "aws_cognito_mfa_types": [],
    "aws_cognito_password_protection_settings": {
        "passwordPolicyMinLength": 8,
        "passwordPolicyCharacters": [
            "REQUIRES_LOWERCASE",
            "REQUIRES_UPPERCASE",
            "REQUIRES_NUMBERS",
            "REQUIRES_SYMBOLS"
        ]
    },
    "aws_cognito_verification_mechanisms": [],
    "aws_user_files_s3_bucket": "active-operations181848-staging",
    "aws_user_files_s3_bucket_region": "us-east-1"
};


export default awsmobile;
