import { createSlice } from '@reduxjs/toolkit'

const initialState = {
  covenance: {},
  covenanceList: []
}

export const covenanceSlice = createSlice({
  name: 'CASE_MANAGER',
  initialState,
  reducers: {
    setCovenance: (state, action) => {
      state.covenance = { ...action.payload }
    },
    setCovenanceList: (state, action) => {
      console.log(action.payload)
      state.covenanceList = [...action.payload]
    }
  }
})

export const { setCovenance, setCovenanceList } = covenanceSlice.actions

export default covenanceSlice.reducer
