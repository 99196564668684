import React from 'react'

const Skeleton = ({ numberCols, numerRows }) => {
  function range (start, end) {
    const array = []
    for (let i = start; i <= end; i++) {
      array.push(array)
    }
    return array
  }
  return (
    <div>
      <div
        className="_card-common s-p-x-6 s-m-t-8"
        style={{ height: '72px', display: 'flex', alignItems: 'center' }}
      >
        <div
          style={{
            width: '40px',
            height: '40px',
            borderRadius: '8px',
            marginLeft: '24px'
          }}
        >
          <bdb-at-skeleton></bdb-at-skeleton>
        </div>
        <div
          style={{
            width: '180px',
            height: '20px',
            marginLeft: '16px',
            marginRight: '4%'
          }}
        >
          <bdb-at-skeleton></bdb-at-skeleton>
        </div>
        <div style={{ marginLeft: '10%' }}>
          <div
            style={{
              width: '150px',
              height: '18px',
              margin: '0px 0px 8px 0px'
            }}
          >
            <bdb-at-skeleton></bdb-at-skeleton>
          </div>
          <div style={{ width: '50px', height: '14px' }}>
            <bdb-at-skeleton></bdb-at-skeleton>
          </div>
        </div>
        <div style={{ marginLeft: '12%' }}>
          <div
            style={{
              width: '150px',
              height: '18px',
              margin: '0px 0px 8px 0px'
            }}
          >
            <bdb-at-skeleton></bdb-at-skeleton>
          </div>
          <div style={{ width: '50px', height: '14px' }}>
            <bdb-at-skeleton></bdb-at-skeleton>
          </div>
        </div>
      </div>

      <div style={{ display: 'flex', marginTop: '70px' }}>
        <div style={{ width: '180px', height: '20px' }}>
          <bdb-at-skeleton></bdb-at-skeleton>
        </div>
        <div style={{ width: '220px', height: '20px', marginLeft: '16px' }}>
          <bdb-at-skeleton></bdb-at-skeleton>
        </div>
      </div>
      <div className="_card-common s-m-t-4">
        {[...range(1, numerRows)].map((col, idx) => (
          <div
            key={idx}
            style={{
              display: 'flex',
              borderBottom: '1px solid var(--sherpa-carbon-200)',
              justifyContent: 'space-around'
            }}
          >
            {[...range(1, numberCols)].map((row, idx) => (
              <div key={idx} style={{ height: '55px' }}>
                <div
                  style={{ width: '100px', height: '20px', margin: '18px 0px' }}
                >
                  <bdb-at-skeleton></bdb-at-skeleton>
                </div>
              </div>
            ))}
          </div>
        ))}
      </div>
    </div>
  )
}

export default Skeleton
