import { API, Auth } from 'aws-amplify'
import { discoverUser } from '../helpers/mapper'
import { setUserValidate } from '../store/slices/caseManager/caseManagerSlice'
import { setMsgError, startLogin } from '../store/slices/sessionRedux/sessionSlice'

const errorIdm = 'No se encontró tu usuario en IDM, por favor contacta con el administrador.'

export const validateUser = (loader, navigate, pathRedirect, userToValidate, isLogin) => {
  return async (dispatch) => {
    dispatch(startLogin(true))
    loader.openLoader()
    const accessToken = (await Auth.currentSession())
      .getAccessToken()
      .getJwtToken()
    const apiName = 'ApiGatewayApi'
    let user = discoverUser(Auth.user.username)
    user = (isLogin && (user === 'liderdesembolso')) ?
      'LDDESEM1' :
        (isLogin && (user === 'gestordesembolso')) ?
          'GEDESEM1' :
            (isLogin && (user === 'adminproductividad')) ?
              'NPINED2' :
                (isLogin && (user === 'STARAZ1')) ?
                  'NOIDM' :
                    (isLogin) ? user : userToValidate
    sessionStorage.setItem('userLogin', user)
    const path = `user/${user}`
    const init = {
      headers: {
        Authorization: accessToken
      }
    }
    await API.get(apiName, path, init)
      .then((response) => {
        if (isLogin) {
          sessionStorage.setItem('nameUser', response.data.fullName)
          sessionStorage.setItem('user', response.data.userName)
          sessionStorage.setItem('documentUser', (response.data.userCn).replace('SM', ''))
          navigate(pathRedirect, { replace: true })
        } else {
          dispatch(setUserValidate(response))
        }
      })
      .catch((error) => {
        console.log({ error })
        dispatch(isLogin ? setMsgError(errorIdm) : setUserValidate(errorIdm))
      })
      .finally(() => {
        loader.closeLoader()
      })
  }
}
