import moment from 'moment-timezone'
import 'moment/locale/es'
import { tZColombia } from '../constants'

export const getDayNow = () => {
  return moment().tz(tZColombia).locale('es')
}

export const transformDateToMoment = (date) => {
  return moment(date)
}

export const transformDateStringToMoment = (dateString) => {
  return moment(dateString)
}

export const concatDateAndHourString = (date, hour) => {
  const dateMoment = moment(date).format('YYYY-MM-DD')
  const hourMoment = moment(hour, 'hh:mm:ss').format('HH:mm:ss')
  return moment(dateMoment + 'T' + hourMoment)
}

export const diffMomentDates = (dateA, dateB, unit) => {
  return dateA.diff(dateB, unit)
}
