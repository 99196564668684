import { createSlice } from '@reduxjs/toolkit'

const initialState = {
  openCase: {},
  cases: [],
  selectedType: {},
  managerProcess: {},
  modalInfo: {},
  getCaseNumber: {},
  pausedCases: [],
  openCases: [],
  countCases: {},
  caseInfo: {},
  selectedCase: {},
  successUpdate: false,
  caseFoundError: false,
  userValidate: {},
  configSidebar: {
    index: 0
  }
}

export const caseManagerSlice = createSlice({
  name: 'CASE_MANAGER',
  initialState,
  reducers: {
    setOpenCase: (state, action) => {
      state.openCase = { ...action.payload }
    },
    setListCases: (state, action) => {
      state.cases = [...action.payload]
    },
    setManagerProcess: (state, action) => {
      state.managerProcess = { ...action.payload }
    },
    setSelectedType: (state, action) => {
      state.selectedType = { ...action.payload }
    },
    setModal: (state, action) => {
      state.modalInfo = { ...action.payload }
    },
    getPausedCases: (state, action) => {
      state.pausedCases = [...action.payload]
    },
    getOpenCases: (state, action) => {
      state.openCases = [...action.payload]
    },
    setUpdate: (state, action) => {
      state.uploadCase = { ...action.payload }
    },
    setGetCaseNumber: (state, action) => {
      state.getCaseNumber = { ...action.payload }
    },
    setCountCases: (state, action) => {
      state.countCases = { ...state.countCases, ...action.payload }
    },
    setCaseInfo: (state, action) => {
      state.caseInfo = { ...action.payload }
    },
    setSelectedCase: (state, action) => {
      state.selectedCase = { ...action.payload }
    },
    setCaseFoundError: (state, action) => {
      state.caseFoundError = action.payload
    },
    setSuccessUpdate: (state, action) => {
      state.successUpdate = action.payload
    },
    setUserValidate: (state, action) => {
      state.userValidate = action.payload
    },
    setIndexSidebar: (state, action) => {
      state.configSidebar = action.payload
    }
  }
})

// Action creators are generated for each case reducer function
export const {
  setOpenCase,
  setListCases,
  setManagerProcess,
  setSelectedType,
  setModal,
  getPausedCases,
  getOpenCases,
  setUpdate,
  setGetCaseNumber,
  setCountCases,
  setCaseInfo,
  setSelectedCase,
  setCaseFoundError,
  setSuccessUpdate,
  setUserValidate,
  setIndexSidebar
} = caseManagerSlice.actions

export default caseManagerSlice.reducer
