import React, { useRef, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Navigate } from 'react-router'
import { setRouteBack } from '../../store/slices/loader/loaderSlice'
import { ReserveConfigFactory } from './ReserveConfig'

const ConfimationReserveView = () => {
  const toast = useRef()
  const dispatch = useDispatch()

  const guaranteeInfo = useSelector((state) => state.reserve.guaranteeInfo)
  const configs = ReserveConfigFactory(guaranteeInfo.statusReserve)

  const handleCopy = () => {
    const copyText = document.getElementById('field5-replaced-false')
    navigator.clipboard.writeText(copyText.innerText)
  }

  useEffect(() => {
    if (guaranteeInfo.statusReserve) toast.current.show()
  }, [guaranteeInfo])

  useEffect(() => {
    dispatch(setRouteBack('/reserve/search'))
  }, [dispatch])

  return !guaranteeInfo.statusReserve
    ? (
    <Navigate to="/reserve/search" />
      )
    : (
    <div>
      <bdb-at-toast
        data-cy="toast"
        ref={toast}
        type={configs.toast.icon}
        text-desc={configs.toast.message}
        is-close="false"
        time={15000}
      ></bdb-at-toast>
      <section className="_confirmationReserve-card-container s-m-t-10">
        <div>
          <bdb-at-interactive-tag
            data-cy="tag"
            style={{ display: 'inline-block' }}
            type={configs.tag.icon}
            text={configs.tag.message}
          ></bdb-at-interactive-tag>
          {!!configs.tag.auxMsg && (
            <span className="sherpa-typography-title-3 _confirmationReserve-replaced-msg">
              {configs.tag.auxMsg}
            </span>
          )}
        </div>
        <span className="sherpa-typography-heading-5">
          Garantía {guaranteeInfo.garaCodigo}
        </span>
        <div className="_flex-wrap s-m-t-6">
          {configs.fieldsToShow.map((field, idx) => (
            <div
              id={`col-${idx}`}
              key={idx}
              className={`s-m-b-4 _confirmationReserve-col-info${
                configs.othersFields ? '-bigger' : ''
              }`}
            >
              <label className="sherpa-typography-label-1">
                {' '}
                {field.label}
                <span
                  className="s-m-t-1"
                  id={`field${idx}-replaced-${!!configs.tag.auxMsg}`}
                >
                  {guaranteeInfo[field.attribute]}
                </span>
              </label>
            </div>
          ))}
        </div>
        {configs.hasCopyReserve && (
          <button
            onClick={handleCopy}
            data-cy="btn-copy"
            id="btn-copy"
            className="bdb-at-btn bdb-at-btn--primary bdb-at-btn--lg s-m-t-4"
          >
            Copiar Nº de reserva
          </button>
        )}
        {!!configs.othersFields && (
          <bdb-at-text-area
            status="DISABLED"
            value={guaranteeInfo[configs.othersFields.attribute]}
            label={configs.othersFields.label}
            placeholder=""
            height="85px"
          ></bdb-at-text-area>
        )}
      </section>
    </div>
      )
}

export default ConfimationReserveView
