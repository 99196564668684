import React, { Fragment } from 'react'
import PropTypes from 'prop-types'

const CompoundModal = ({ backdrop, children }) => {
  return (
    <Fragment>
        <bdb-at-backdrop
            ref={backdrop}
        >
        <div slot='bodyBackdrop' style={{ width: '100%', height: '100%' }} className='_flex-center'>
            {children}
        </div>
        </bdb-at-backdrop>
    </Fragment>
  )
}

export default CompoundModal

CompoundModal.propTypes = {
  backdrop: PropTypes.object.isRequired,
  children: PropTypes.node.isRequired
}
