import React from 'react'
import { useAuthenticator } from '@aws-amplify/ui-react'
import { Navigate } from 'react-router'

export const PrivateRoute = ({ children }) => {
  const { route } = useAuthenticator((context) => [context.route])

  return route === 'authenticated'
    ? (
        children
      )
    : route === 'idle'
      ? (
    <div>Cargando ...</div>
        )
      : (
    <Navigate to="/login" />
        )
}
