import { SELF_ASSIGN_MANAGEMENT } from '../constants'
import { encryptUsingAES256 } from './encryption'
export const redirectSelfAssignment = (operator) => {
  console.log('Redirect to SelfAssignment')

  if (process.env.REACT_APP_ENDPOINT_SELF_ASSIGN !== null && process.env.REACT_APP_ENDPOINT_SELF_ASSIGN !== '') {
    const endpoint = process.env.REACT_APP_ENDPOINT_SELF_ASSIGN
    const keyToken = sessionStorage.getItem('keyToken')
    const token = sessionStorage.getItem('token')
    window.location.href = endpoint
      + '?username=' + btoa(operator.fullName)
      + '&orderDisb=0'
      + '&userLogin=' + btoa(operator.user)
      + '&docType=' + btoa(operator.docType)
      + '&documentNumberAdviser=' + operator.docNum
      + '&keyToken=' + btoa(keyToken)
      + '&token=' + btoa(token)
      + '&rolUser=' + btoa(operator.rol)
      + '&management=' + btoa(operator.management)
  }
}
export const loginSelfAssign = (idmAuth) => {
  try {
    const userOperationsIDM = {
      docNum: btoa(idmAuth.userCn),
      docType: encryptUsingAES256('C', idmAuth.userCn),
      fullName: encryptUsingAES256(idmAuth.fullName, idmAuth.userCn),
      rol: encryptUsingAES256(idmAuth.cn, idmAuth.userCn),
      user: encryptUsingAES256(idmAuth.userName, idmAuth.userCn),
      token: idmAuth.token,
      tokenKey: idmAuth.tokenKey,
      management: getManagementForSelfAssign(idmAuth.cn)
    }
    redirectSelfAssignment(userOperationsIDM)
  } catch (error) {
    console.log({ error })
  }
}
const getManagementForSelfAssign = (idmRol) => {
  return SELF_ASSIGN_MANAGEMENT[idmRol] ?? null
}
