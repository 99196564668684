const rols = {
  leaders: '/leader/disbursement/indicators',
  managers: '/home'
}
export class RolesAuth {
  redirectRoute (groups) {
    let path = ''
    for (const rol in rols) {
      const findRol = groups.includes(rol)
      if (!findRol) continue
      path = rols[rol]
      break
    }
    return path
  }
}
