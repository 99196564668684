import React, { useEffect, useState, useRef } from 'react'

const orderDisbursement = 'Orden desembolso'
const flatFile = 'Archivo plano'
const title = 'Documentos cargados para el caso'
// TO DO: Connect backend
const documents = [
  {
    text: 'Documento de identificación',
    value: {
      url: 'https://bbta-bucket-files-public-qa.s3.amazonaws.com/Notificacion_docs_mantiz.pdf',
      fileName: 'Notificacion_docs_mantiz.pdf',
      fileExtension: 'PDF'
    }
  },
  {
    text: 'Cámara de comercio',
    value: {
      url: 'https://bbta-bucket-files-public-qa.s3.amazonaws.com/FondoTeams_1.png',
      fileName: 'FondoTeams_1.png',
      fileExtension: 'PNG'
    }
  },
  {
    text: 'Pagaré',
    value: {
      url: 'https://bbta-bucket-files-public-qa.s3.amazonaws.com/gian-porsius-b9qBWrxmbUE-unsplash.jpg',
      fileName: 'gian-porsius-b9qBWrxmbUE-unsplash.jpg',
      fileExtension: 'JPG'
    }
  },
  {
    text: 'Tabla de tasas o pricing',
    value: {
      url: 'https://bbta-bucket-files-public-qa.s3.amazonaws.com/191001.tiff',
      fileName: '191001.tiff',
      fileExtension: 'TIFF'
    }
  }
]

const PreviewDocs = () => {
  const viewer = useRef()
  const header = useRef()

  const [docSelected, setDocSelected] = useState()

  const handleClick = (e) => {
    setDocSelected(e)
    viewer.current.openViewer()
  }
  const openViewr = (e) => {}
  const closeTab = (e) => {
    window.close()
  }
  useEffect(() => {
    viewer.current.addEventListener('btnEvent', openViewr)
    header.current.addEventListener('closeBtnClicked', closeTab)
  }, [])

  return (
    <div>
      <bdb-ml-header
        ref={header}
        back="FALSE"
        close="TRUE"
        close-icon="ico-logout"
      />
      <bdb-ml-document-viewer
        hidden-buttons
        hidden-download
        hidden-replace
        hidden-delete
        download-url={docSelected?.url}
        file-extension={docSelected?.fileExtension}
        file-name={docSelected?.fileName}
        ref={viewer}
      />
      <div className="col-lg-7 margin-auto-x s-m-t-7">
        <div className='_flex-justify-end s-m-b-7' style={{ gap: '16px' }}>
        <a
          className="_links-no-decoration"
          style={{ width: '28%' }}
          href="https://bbta-bucket-files-public-qa.s3.amazonaws.com/ODMMIXY_RENTING+DE+ANTIOQUIA+SAS_050014830721102.xls"
        >
        <button
          className='bdb-at-btn bdb-at-btn--secondary bdb-at-btn--sm--ico'
        ><span className='ico-document-download'></span>{orderDisbursement}</button>
        </a>
        <a
          className="_links-no-decoration"
          style={{ width: '21%' }}
          href="https://bbta-bucket-files-public-qa.s3.amazonaws.com/9812.xls"
        >
        <button
          className='bdb-at-btn bdb-at-btn--secondary bdb-at-btn--sm--ico'
        ><span className='ico-document-download'></span>{flatFile}</button>
        </a>
        </div>
        <span className='sherpa-typography-label-1'>{title}</span>
        <div className='s-m-t-5'>
        {
          documents.map((doc, idx) => (
          <div key={idx} className='_previewDocs-card-doc col-lg-12 s-m-b-4'>
            <span className='sherpa-typography-title-3'>
            {doc.text}
            </span>
            <button
              className='bdb-at-btn  bdb-at-btn--link bdb-at-btn--sm'
              style={{ width: '5%' }}
              onClick={() => handleClick(doc.value)}
            ><label>Ver<span>{'>'}</span></label></button>
          </div>
          ))
        }
        </div>
      </div>
    </div>
  )
}

export default PreviewDocs
