import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import Header from '../../components/HeaderComponent/Header'
import {
  deleteCovenance,
  getCovenanceList,
  saveCovenance,
  updateCovenance
} from '../../store/slices/covenanceManager/covenanceThunks'
import './CovenanceView.scss'

const CovenanceView = () => {
  const covenanceList = useSelector((state) => state.covenance.covenanceList)

  const dispatch = useDispatch()
  useEffect(() => {
    dispatch(getCovenanceList())
  }, [dispatch])

  const handleNewCovenance = () => {
    dispatch(saveCovenance())
  }
  const handleDeleteCovenance = (id) => {
    dispatch(deleteCovenance(id))
  }
  const handleUpdateCovenance = (item) => {
    dispatch(updateCovenance(item))
  }

  return (
    <>
      <Header />
      <div className="box-container">
        <button
          onClick={handleNewCovenance}
          className="bdb-at-btn  bdb-at-btn--secondary bdb-at-btn--lg bdb-at-btn--fluid margin-auto-right"
        >
          Agregar Convenio
        </button>
        <table className="s-m-t-8">
          <thead>
            <tr>
              <th>Nombre</th>
              <th>Código</th>
              <th>Status</th>
              <th>Usuarios</th>
              <th>Opcion</th>
            </tr>
          </thead>
          <tbody>
            {covenanceList.map((el) => (
              <tr key={el.id}>
                <td> {el.name} </td>
                <td> {el.code} </td>
                <td> {el.status} </td>
                <td> {el.users} </td>
                <td>
                  <button
                    onClick={() => handleDeleteCovenance(el.id)}
                    className=" bdb-at-btn  bdb-at-btn--secondary bdb-at-btn--sm bdb-at-btn--fluid margin-auto-right"
                  >
                    Eliminar
                  </button>
                  <button
                    onClick={() => handleUpdateCovenance(el)}
                    className="bdb-at-btn  bdb-at-btn--secondary bdb-at-btn--sm bdb-at-btn--fluid margin-auto-right"
                  >
                    Editar
                  </button>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </>
  )
}

export default CovenanceView
