import React, { useEffect, useRef, useState, useContext } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useNavigate } from 'react-router'
import { LoaderContext } from '../../Contexts/LoaderContex'
import { setSelectedCase, setUserValidate } from '../../store/slices/caseManager/caseManagerSlice'
import { updateCaseFields } from '../../store/slices/caseManager/caseManagerThunks'
import { validateUser } from '../../services/idmService'

const options = [
  { text: 'Media', value: '2' },
  { text: 'Alta', value: '1' }
]

const PrioritizeReassignCaseLeader = () => {
  const unassigned = 'Sin asignar'
  const dropdownPriority = useRef()
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const loading = useContext(LoaderContext)
  const loader = useRef()
  const toggle = useRef()
  const [priority, setPriority] = useState('')
  const [newUser, setNewUser] = useState('')
  const [disableInput, setDisableInput] = useState(false)
  const [isValidUser, setValidUser] = useState(true)
  const { selectedCase } = useSelector((state) => state.casesManager)
  const reassign = window.location.href.split('/').includes('reassign')
  const prioritize = window.location.href.split('/').includes('prioritize')
  const idmResponse = useSelector((state) => state.casesManager.userValidate)

  useEffect(() => {
    dispatch(setUserValidate({}))
    const { current: currentDropdown } = dropdownPriority
    const { current: currentToggle } = toggle
    currentDropdown.addEventListener('elementSelectedAtom', handleSelect)
    currentDropdown.addEventListener('atInputChanged', handleChange)
    currentToggle?.addEventListener('mlToggleChanged', handleChecked)
    return () => {
      if (currentDropdown) {
        currentDropdown.removeEventListener('elementSelectedAtom', handleSelect)
        currentDropdown.removeEventListener('atInputChanged', handleChange)
      }
      if (currentToggle) currentToggle.removeEventListener('mlToggleChanged', handleChecked)
    }
  }, [])

  useEffect(() => {
    const rolValidate = idmResponse?.data?.userGroups.filter(element => element.includes('cn=GestoresDesembolso') || element.includes('cn=GestorProductividad')) // SE INCLUYE EL GESTOR DE PRODUCTIVIDAD PARA QUE EL TESTER PUEDA HACER LAS PRUEBAS. EN REALIDAD SOLO DEBE IR EL GESTOR DE DESMBOLSO
    const isDisbursmentManager = !!rolValidate && rolValidate.length > 0
    setValidUser(isDisbursmentManager || Object.entries(idmResponse).length === 0)
    if (isDisbursmentManager) { updateCase() }
  }, [idmResponse])

  const handleSelect = ({ detail }) => {
    setPriority(detail.value)
  }
  const handleChange = ({ detail }) => {
    setNewUser(detail.value)
  }
  const handleChecked = ({ detail }) => {
    dropdownPriority.current.setValue(detail.value ? '' : unassigned)
    setDisableInput(!detail.value)
    setNewUser(unassigned)
  }
  const handleClick = () => {
    if (reassign && newUser !== unassigned) {
      dispatch(validateUser(loader.current, navigate, null, newUser, false))
    } else {
      updateCase()
    }
  }
  const handleCancel = () => {
    navigate('/leader/disbursement/search-case')
    dispatch(setSelectedCase({}))
  }

  const updateCase = () => {
    const dataCase = {
      requestNumber: selectedCase.requestNumber,
      file: selectedCase.file,
      hourAssignment: selectedCase.hourAssignment,
      id: selectedCase.id,
      dateAssignment: selectedCase.dateAssignment,
      process: selectedCase.process,
      createdAt: selectedCase.createdAt,
      reqStatus: newUser === unassigned ? 'PENDING' : selectedCase.reqStatus,
      fileOrder: selectedCase.fileOrder,
      reasonReturn: selectedCase.reasonReturn,
      priority: prioritize ? Number(priority) : selectedCase.priority,
      reqUser: reassign ? (newUser === unassigned ? '-' : newUser) : selectedCase.reqUser
    }
    dispatch(updateCaseFields(dataCase, navigate, '/leader/disbursement/search-case', loading))
  }
  return (
    <div>
      <bdb-ml-loader ref={loader} />
      <>
        <div className='_card-common s-p-5 s-m-t-7'>
          <div className='header-summary s-m-b-6'>
            <div className='sherpa-typography-heading-6'>
              Caso {selectedCase.requestNumber}
            </div>
            <div className='text-carbon-800'>
              {selectedCase.process}
            </div>
          </div>
          <hr  className='line-separator' />
          <div className='row'>
            <div className="col-sm-12 col-lg-3 no-padding s-m-t-4 ">
              <label className='text-carbon-800'>
                Documento cliente:
              </label>
              <div className='text-carbon-900 s-m-t-1'>
                {selectedCase.document}
              </div>
            </div>
            <div className="col-xs-12 col-sm-6 col-xl-3 col-lg-4 col-md-6 no-padding s-m-t-4 min-w-300 max-w-400">
              { prioritize ?
                <>
                  <label className='text-carbon-800'>
                    Gestor:
                  </label>
                  <div className='text-carbon-900 s-m-t-1'>
                    {selectedCase.reqUser ?? unassigned}
                  </div>
                </>
                :
                reassign ?
                  <bdb-at-input
                    style={{ width: '100%' }}
                    ref={dropdownPriority}
                    label="Gestor:"
                    placeholder={selectedCase.reqUser}
                    status={ disableInput ? 'DISMESSAGE' : !isValidUser ? 'ERROR' : 'HELP'}
                    message={ disableInput ? '' : !isValidUser ? 'Usuario de red incorrecto' : 'Escribe el usuario o selecciona Sin asignar'}
                    data-cy='reassign-input'
                    type='TEXT'
                    view-mode
                  ></bdb-at-input>
                  :
                  null
              }
            </div>
            { prioritize ?
            <div className="col-xs-12 col-sm-6 col-xl-3 col-lg-4 col-md-6 no-padding s-m-t-4 min-w-300 max-w-400">
                <bdb-at-dropdown name="1" label='Prioridad' status="HELP" message="Cambia la prioridad a media o alta"
                  placeholder='Selecciona' data-cy='priority-dropdown'
                  options={JSON.stringify(options)}
                  ref={dropdownPriority}
                ></bdb-at-dropdown>
              </div>
              :
              <div className="col-lg-3 s-m-t-10">
                <bdb-at-toggle ref={toggle} text={unassigned} data-cy='reassign-toggle'></bdb-at-toggle>
              </div>
            }
          </div>
          <div className="row">
            <div className="col-auto no-padding s-m-t-4 ">
              <button data-cy="save-priority-btn" className='bdb-at-btn  bdb-at-btn--primary bdb-at-btn--lg' onClick={handleClick} disabled={!(priority || newUser)} >Guardar cambios</button>
            </div>
            <div className="col-auto no-padding s-m-t-4 s-m-l-4">
              <button data-cy="cancel-priority-btn" className='bdb-at-btn  bdb-at-btn--secondary bdb-at-btn--lg' onClick={handleCancel}>Cancelar</button>
            </div>
          </div>
        </div>
      </>
    </div>
  )
}

export default PrioritizeReassignCaseLeader
