import React, { StrictMode } from 'react'
import { createRoot } from 'react-dom/client'
import { Provider } from 'react-redux'
// eslint-disable-next-line
import Symbol_observable from 'symbol-observable'
import './index.css'
import App from './App'
import reportWebVitals from './reportWebVitals'
import {
  applyPolyfills,
  defineCustomElements
} from '@npm-bbta/bbog-dig-dt-webcomponents-lib/loader'
import { store } from './store/store'

const rootElement = document.getElementById('root')
const root = createRoot(rootElement)

root.render(
  <StrictMode>
    <Provider store={store}>
      <App />
    </Provider>
  </StrictMode>
)
applyPolyfills().then(() => {
  defineCustomElements()
})
reportWebVitals()
