import {
  APPROVED_STATUS,
  REJECTED_STATUS,
  REPLACED_STATUS
} from '../../constants'

const fieldsSuccess = [
  { label: 'Nombre del cliente', attribute: 'nombre' },
  { label: 'Fecha reserva', attribute: 'fechaReserva' },
  { label: 'Monto', attribute: 'valorGarantiaOriginal' },
  { label: 'Tipo y Número de documento', attribute: 'fullDocument' },
  { label: 'Producto FNG', attribute: 'prfnCodigo' },
  { label: 'Número reserva', attribute: 'reservaCodigo' }
]
const fieldsReject = [
  { label: 'Nombre del cliente', attribute: 'nombre' },
  { label: 'Tipo y Número de documento', attribute: 'fullDocument' }
]

const causalReject = {
  label: 'Causal de rechazo:',
  attribute: 'observaciones'
}

const componentConstructor = (icon, message, auxMsg) => ({
  icon,
  message,
  auxMsg
})

class ReserveConfig {
  constructor (fieldsToShow, toast, tag, othersFields, hasCopyReserve) {
    this.fieldsToShow = fieldsToShow
    this.toast = toast
    this.tag = tag
    this.othersFields = othersFields
    this.hasCopyReserve = hasCopyReserve
  }
}

export const ReserveConfigFactory = (status) => {
  if (!status) return
  switch (status) {
    case APPROVED_STATUS:
      return new ReserveConfig(
        fieldsSuccess,
        componentConstructor('SUCCESS', 'La reserva fue aprobada exitosamente'),
        componentConstructor('SUCCESS', 'Aprobada'),
        null,
        true
      )
    case REPLACED_STATUS:
      return new ReserveConfig(
        fieldsSuccess,
        componentConstructor('SUCCESS', 'La reserva fue aprobada exitosamente'),
        componentConstructor(
          'WARNING',
          'Sustituida',
          'Cód de garantía sustituido a MP080'
        ),
        null,
        true
      )
    case REJECTED_STATUS:
      return new ReserveConfig(
        fieldsReject,
        componentConstructor('ERROR', 'La reserva fue rechazada'),
        componentConstructor('ERROR', 'Rechazada'),
        causalReject,
        false
      )
    default:
  }
}
