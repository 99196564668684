import { API, Auth } from 'aws-amplify'
import { loginSelfAssign } from './redirectService'
import { encryptUsingAES256 } from './encryption'

export const  getLoginAdviser = (loader) => {
  return async (dispatch) => {
    const user = sessionStorage.getItem('userLogin')
    loader.openLoader()
    const accessToken = (await Auth.currentSession())
      .getAccessToken()
      .getJwtToken()
    const apiName = 'ApiLoginAdviser'
    const path = 'login/auth'
    const init = {
      headers: {
        Authorization: accessToken
      },
      body: {
        usuarioNt: user
      }
    }
    await API.post(apiName, path, init)
      .then((response) => {
        initValues(response)
      })
      .catch((error) => {
        console.log({ error })
      })
      .finally(() => loader.closeLoader())
  }
}
export const initValues = async (data) => {
  const {  idmAuth, officeInfo, grantsViews } = data
  const dtoUser = grantsViews?.dtoUser ? grantsViews.dtoUser : null
  const keyToken = idmAuth.tokenKey
  const token = idmAuth.token
  let documentNumberAdviser = idmAuth.userCn
  documentNumberAdviser = documentNumberAdviser.replace('SM', '').replace('sm', '')

  sessionStorage.setItem('keyToken', encryptUsingAES256(keyToken, documentNumberAdviser))
  sessionStorage.setItem('token', encryptUsingAES256(token, documentNumberAdviser))
  sessionStorage.setItem('documentNumberAdviser', documentNumberAdviser)
  sessionStorage.setItem('userRolApprover', dtoUser ? dtoUser.rol : '')
  sessionStorage.setItem('nitName', idmAuth.userName)
  sessionStorage.setItem('username', idmAuth.fullName)
  sessionStorage.setItem('grantsView', encryptUsingAES256(JSON.stringify(grantsViews), documentNumberAdviser))

  if (officeInfo) {
    sessionStorage.setItem('codeOffice', officeInfo.id)
    sessionStorage.setItem('isMicrofinance', officeInfo.microfinanzas.toString())
    sessionStorage.setItem('tutorOffice', officeInfo.tutor)
    sessionStorage.setItem('districtGer', officeInfo.districtGer)
  }
  const obj = {
    ...idmAuth,
    token
  }
  loginSelfAssign(obj)
}
