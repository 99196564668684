import {
  APPROVED_STATUS,
  REALIZADA_STATUS,
  RECHAZADA_STATUS,
  REJECTED_STATUS,
  REPLACED_PRODUCT,
  REPLACED_STATUS
} from '../constants'

export const validateObjectFieldsNotEmpty = (object, arrayFields) => {
  const arrayValid = []
  Object.entries(object).forEach(([key, value]) => {
    if (arrayFields.includes(key) && !!value) arrayValid.push(true)
  })
  return arrayFields.length === arrayValid.length
}

export const discoverStatusReserve = (
  efngCode,
  productReplaced,
  replacedFNGProduct
) => {
  const isRea = efngCode === REALIZADA_STATUS
  const isRech = efngCode === RECHAZADA_STATUS
  const isReplaced = replacedFNGProduct === REPLACED_PRODUCT && productReplaced
  if (isRech) return REJECTED_STATUS
  if (isRea && isReplaced) return REPLACED_STATUS
  return APPROVED_STATUS
}
