import { createSlice } from '@reduxjs/toolkit'

const initialState = {
  icon: '',
  title: '',
  subTitle: '',
  options: '[{"id":"0","value":"Aceptar"}]',
  textAreaValue: '',
  dropDownValue: ''
}

export const bdbModalSlice = createSlice({
  name: 'BDB_MODAL_COMPONENT',
  initialState,
  reducers: {
    setModalParameters: (state, action) => {
      state.icon = action.payload.icon
      state.title = action.payload.title
      state.subTitle = action.payload.subTitle
      state.options = action.payload.options
    },
    setTextAreaValue: (state, action) => {
      state.textAreaValue = action.payload
    },
    setDropDownValue: (state, action) => {
      state.dropDownValue = action.payload
    },
    resetModalParameters: (state) => {
      state.icon = initialState.icon
      state.title = initialState.title
      state.subTitle = initialState.subTitle
      state.options = initialState.options
      state.textAreaValue = initialState.textAreaValue
      state.dropDownValue = initialState.dropDownValue
    }
  }
})

// Action creators are generated for each case reducer function
export const { setModalParameters, resetModalParameters, setTextAreaValue, setDropDownValue } =
bdbModalSlice.actions

export default bdbModalSlice.reducer
