/* eslint-disable func-call-spacing */
import React, { useRef, useEffect, useState, useCallback } from 'react'
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom'
import { Navigate } from 'react-router'
import { PrivateRoute } from './PrivateRoute'
import { DisbursementRoutes } from './DisbursementRoutes'
import { Login } from '../auth/Login'
import { ManagerRoutes } from './ManagerRoutes'
import CovenanceView from '../pages/CovenanceView/CovenanceView'
import {  Auth, Hub } from 'aws-amplify'
import { LoaderContext } from '../Contexts/LoaderContex'
import { useSelector, useDispatch } from 'react-redux'
import { LeaderRoutes } from './LeaderRoutes'
import  CloseModalcomponent from '../components/CloseModalcomponent/CloseModalcomponent'
import UseCountdown  from '../components/CloseModalcomponent/CountdownTimer'
import { dataHub, AWS_REGION }  from '../constants'
import { startCountDown, startCloseSession } from '../store/slices/sessionRedux/sessionSlice'
import { Interactions } from '../services/interactions'
const AWS = require('aws-sdk')

let cacheTime = 0
let kinesis
let funnel = []
let beginTime
let funnelTmp = { funnelId: 0, flow: '', journey: '', step: '', idElement: '', valueElement: '',  htmlPage: '', eventType: '', funnelTime: 0 }

export const AppRouter = () => {
  const loader = useRef()
  const dispatch = useDispatch()
  const [loading, setLoading] = useState(loader.current)
  const [coords, setCoords] = useState({ B: 0, x: 0, y: 0 })
  const callCredentials = async () => {
    await Auth.currentCredentials().then(() => {
      kinesis =  new AWS.Kinesis({
        apiVersion: '2013-12-02',
        region: AWS_REGION,
        ...Auth.Credentials._credentials
      })
    })
  }
  callCredentials()
  const message = useSelector((state) => state.loader.message)
  const countDown = useSelector((state) => state.sessionRidux.countDown)

  Object.keys(window).forEach(key => {
    window.addEventListener(key.slice(2), event => {
      if (cacheTime !== event.timeStamp && /^on(?!mouse|pointer|device|key|blur|focus|wheel|scroll)/.test(key)) {
        cacheTime = event.timeStamp;
        ({ funnel, funnelTmp, beginTime } =  Interactions(funnelTmp, funnel, event, kinesis, Auth, beginTime))
      }
    })
  })

  useEffect(() => {
    const _setLoading = () => setLoading(loader.current)
    _setLoading()
  }, [])

  useEffect(() => {
    Hub.listen('activeDownTimer', (data) => {
      console.log(coords)
      if (dataHub.indexOf(data.payload.event) === 0) { dispatch(startCountDown(true)) }
      if (dataHub.indexOf(data.payload.event) === 1) { dispatch(startCloseSession(true)) }
    })
  }, [dispatch])

  const handler = useCallback(({ button, clientX, clientY }) => { setCoords({ B: button, x: clientX, y: clientY }) }, [setCoords])
  UseCountdown('mousemove', handler)

  return (
    <Router>
        <bdb-ml-loader ref={loader} text-label={message} />
      <Routes>
        <Route path="/login" element={<Login />} />
        <Route path="/covenance" element={<CovenanceView />} />
        <Route
          path="/*"
          element={
            <PrivateRoute>
                {
                  countDown &&
                  <CloseModalcomponent></CloseModalcomponent>
                }
                {loading &&
                <LoaderContext.Provider value={loading}>
                  <DisbursementRoutes></DisbursementRoutes>
                  <ManagerRoutes></ManagerRoutes>
                  <LeaderRoutes></LeaderRoutes>
                </LoaderContext.Provider>
                }
            </PrivateRoute>
          }
        />
        <Route path="/" element={<Navigate to="/login" />} />
      </Routes>
    </Router>
  )
}
