import React, {
  useCallback,
  useContext,
  useEffect,
  useRef,
  useState
} from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useNavigate, useParams } from 'react-router-dom'
import {
  getCasesByStatus,
  getCasePending,
  requestNumberCase,
  updateCase
} from '../../store/slices/caseManager/caseManagerThunks'
import { setModal, setOpenCase } from '../../store/slices/caseManager/caseManagerSlice'
import { modalOpenCases, CasesStates, fieldsPausedCases } from '../../constants'
import { LoaderContext } from '../../Contexts/LoaderContex'

const colsTable = [
  { colName: '', control: 'id' },
  { colName: 'Nº Caso/Control', control: 'text' },
  { colName: 'Documento', control: 'text' },
  { colName: 'Producto', control: 'text' },
  { colName: 'Proceso', control: 'text' },
  { colName: 'Fecha recib.', control: 'text' },
  { colName: 'Hora recib', control: 'text' },
  { colName: 'Estado', control: 'text' },
  { colName: '', control: 'button' }
]

const AnalystView = () => {
  const toast = useRef()
  const modal = useRef()
  const user = sessionStorage.getItem('user')
  const { description } = useSelector(
    (state) => state.casesManager.managerProcess
  )
  const {
    id,
    process: processCase,
    requestNumber
  } = useSelector((state) => state.casesManager.openCase)
  const pausedCases = useSelector((state) => state.casesManager.pausedCases)
  const openCases = useSelector((state) => state.casesManager.openCases)
  const modalInfo = useSelector((state) => state.casesManager.modalInfo)
  const dropDown = useRef()
  const pausedTable = useRef()
  const { type, process } = useParams()
  const navigate = useNavigate()
  const loading = useContext(LoaderContext)
  const [pausedCasesFilter, setPausedCasesFilter] = useState(pausedCases)

  const dispatch = useDispatch()

  useEffect(() => {
    dispatch(getCasesByStatus(CasesStates.PAUSED, user, loading, type))
    dispatch(getCasesByStatus(CasesStates.OPEN, user, loading, type))
  }, [dispatch, loading])

  const buttonClick = useCallback(
    (e) => {
      if (openCases.length > 0) {
        dispatch(setModal(modalOpenCases(openCases[0].requestNumber)))
        modal.current.openAlert()
      } else {
        const selectedCase = pausedCases.find((paused) => paused.id === e.detail.data.id)
        dispatch(updateCase(CasesStates.OPEN, selectedCase, loading))
        // navigate(process ? requestNumber : `${slug}/${requestNumber}`)
      }
    },
    [dispatch, openCases, pausedCases, loading]
  )
  const filterChange = useCallback(
    (e) => {
      dispatch(
        requestNumberCase(
          e && e.detail && e.detail.text ? e.detail.text : undefined
        )
      )
      setPausedCasesFilter(
        pausedCases.filter(
          (element) =>

            element.requestNumber.includes(e.detail.text) ||
            element.document?.includes(e.detail.text)

        )
      )
    },
    [pausedCases, dispatch]
  )

  useEffect(() => {
    const { current: refDrop } = dropDown
    const { current: refTable } = pausedTable
    if (pausedCases.length > 0) {
      setPausedCasesFilter(pausedCases)
      refDrop.addEventListener('eventQueryChanged', filterChange)
      refTable.addEventListener('buttonClick', buttonClick)
    }
    return () => {
      if (refDrop) refDrop.removeEventListener('eventQueryChanged', filterChange)
      if (refTable) refTable.removeEventListener('buttonClick', buttonClick)
    }
  }, [pausedCases, filterChange, buttonClick])

  useEffect(() => {
    const redirectCase = () => {
      navigate(requestNumber)
    }

    if (id) redirectCase()
  }, [id, requestNumber, processCase, type, process, navigate])

  const handleButton = (e) => {
    const existsOpenCase = openCases.length > 0
    if (existsOpenCase) dispatch(setOpenCase(openCases[0]))
    else dispatch(getCasePending(loading, toast.current))
  }

  return (
    <section>
      <bdb-ml-modal
        ref={modal}
        isClose="true"
        icon={modalInfo.icon}
        title-modal={modalInfo.title}
        options-buttons={modalInfo.options}
      ><div slot='content'><div dangerouslySetInnerHTML={{ __html: modalInfo.subTitle }} /></div></bdb-ml-modal>
{      <div className="toast-container s-m-x-13">
        <bdb-at-toast
          ref={toast}
          position="fixed"
          is-close="false"
          type="WARNING"
          title-toast="No hay casos disponibles para este proceso"
        ></bdb-at-toast>
      </div> }
      {pausedCases.length > 0
        ? (
        <div
          style={{ marginTop: '-40px' }}
          className="col-lg-10 margin-auto-x"
        >
          <div className="row s-m-b-10">
            <div className="col-lg-4 no-padding">
              <bdb-at-autocomplete
                style={{ width: '100%' }}
                ref={dropDown}
                label=""
                placeholder="Nº Caso/Control o documento"
                status="ENABLED"
                message=""
                icon
                options="[]"
              ></bdb-at-autocomplete>
            </div>
            <div className="col-lg-8 no-padding">
              <button
                onClick={handleButton}
                className="bdb-at-btn  bdb-at-btn--primary bdb-at-btn--lg bdb-at-btn--fluid margin-auto-left"
              >
                Gestionar caso
              </button>
            </div>
          </div>
          <bdb-ml-dynamic-table
            ref={pausedTable}
            column-table={JSON.stringify(colsTable)}
            row-table={JSON.stringify(fieldsPausedCases(pausedCasesFilter))}
          ></bdb-ml-dynamic-table>
        </div>
          )
        : (
        <>
          <p className="title-label text-center">
            Gestiona aquí los casos de {description}
          </p>
          <div className="button-container">
            <button
              data-cy="btn-get-case"
              onClick={handleButton}
              className="bdb-at-btn  bdb-at-btn--primary bdb-at-btn--lg bdb-at-btn--fluid"
            >
              Gestionar caso
            </button>
          </div>
        </>
          )}
    </section>
  )
}

export default AnalystView
