import React from 'react'
import { Routes, Route } from 'react-router-dom'
import MenuDisbursement from '../pages/HomeDisbursement/MenuDisbursement'

export const DisbursementRoutes = () => {
  return (
    <Routes>
      <Route path="/home" element={<MenuDisbursement />} />
    </Routes>
  )
}
