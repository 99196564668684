import { configureStore } from '@reduxjs/toolkit'
import bdbInputComponentSlice from './slices/bdbInputComponent/bdbInputComponentSlice'
import bdbModalSlice from './slices/bdbModalComponent/bdbModalSlice'
import bdbToastComponentSlice from './slices/bdbToastComponent/bdbToastComponentSlice'
import caseManagerSlice from './slices/caseManager/caseManagerSlice'
import covenanceSlice from './slices/covenanceManager/covenanceSlice'
import loaderSlice from './slices/loader/loaderSlice'
import reserveSlice from './slices/reserve/reserveSlice'
import sessionSlice from './slices/sessionRedux/sessionSlice'

export const store = configureStore({
  reducer: {
    casesManager: caseManagerSlice,
    covenance: covenanceSlice,
    reserve: reserveSlice,
    loader: loaderSlice,
    bdbInputComponent: bdbInputComponentSlice,
    bdbToastComponent: bdbToastComponentSlice,
    bdbModalComponent: bdbModalSlice,
    sessionRidux: sessionSlice
  }
})
