
export class ModalConfig {
  constructor (number) {
    this.icon = 'information'
    this.number = number
    this.subTitle = ''
    this.options = '[{"id":"0","value":"Aceptar"}]'
  }

  setSuccess () {
    this.icon = 'success'
    this.title = '¡Listo!'
    return this
  }

  setError () {
    this.icon = 'error'
    this.title = '¡Lo sentimos!'
    return this
  }

  setApprove () {
    this.options = '[{"id":"0","value":"Aprobar"},{"id":"1","value":"Cancelar"}]'
    this.title = `¿Estás seguro de aprobar el caso ${this.number}?`
  }

  setApprovedSuccess () {
    this.subTitle = `Ha sido aprobado el caso ${this.number}`
  }

  setApprovedError () {
    this.subTitle = `Error aprobando el caso ${this.number}`
  }

  setReturn () {
    this.options = '[{"id":"0","value":"Devolver"},{"id":"1","value":"Cancelar"}]'
    this.title = `¿Estás seguro de devolver el caso ${this.number}?`
  }

  setReturnedSuccess () {
    this.subTitle = `Ha sido devuelto el caso ${this.number}`
  }

  setReturnedError () {
    this.subTitle = `Error devolviendo el caso ${this.number}`
  }

  setPause () {
    this.options = '[{"id":"0","value":"Pausar"},{"id":"1","value":"Cancelar"}]'
    this.title = `¿Estás seguro de pausar el caso ${this.number}?`
  }

  setPausedSuccess () {
    this.subTitle = `Ha sido pausado el caso ${this.number}`
  }

  setPausedError () {
    this.subTitle = `Error pausando el caso ${this.number}`
  }

  addPostError (error) {
    this.subTitle += `\n Nota: No se pudo notificar el caso a gestión diaria (${error})`
  }

  getObject () {
    return {
      icon: this.icon,
      options: this.options,
      number: this.number,
      title: this.title,
      subTitle: this.subTitle
    }
  }
}
