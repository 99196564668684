import React, { useRef, useEffect, useContext, useMemo } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { updateCaseWithProductivity } from '../../store/slices/caseManager/caseManagerThunks'
import { CaseConfigsFactory } from './CaseConfigs'
import { useNavigate, useParams } from 'react-router'
import { LoaderContext } from '../../Contexts/LoaderContex'
import { resetModalParameters, setModalParameters } from '../../store/slices/bdbModalComponent/bdbModalSlice'
import { ModalConfig } from './ModalConfig'
import { setIndexSidebar, setManagerProcess, setOpenCase } from '../../store/slices/caseManager/caseManagerSlice'
import TextModal from '../Modals/TextModal'
import DropDownModal from '../Modals/DropDownModal'
const urlApp = process.env.REACT_APP_PROJECT_URL
const productOptions = (option) => {
  return JSON.stringify([
    {
      label: '1',
      value: '1',
      isChecked: option === '1' ? 'true' : 'false'
    },
    {
      label: '2',
      value: '2',
      isChecked: option === '2' ? 'true' : 'false'
    },
    {
      label: '3',
      value: '3',
      isChecked: option === '3' ? 'true' : 'false'
    }
  ])
}

export const Case = () => {
  const dispatch = useDispatch()
  const modal = useRef()
  const radio = useRef()
  const textModal = useRef()
  const dropDownModal = useRef()
  const navigate = useNavigate()
  const { type, process } = useParams()
  const loading = useContext(LoaderContext)
  const activeCase = useSelector((state) => state.casesManager.openCase)
  const { requestNumber } = activeCase
  const { icon, title, subTitle, options } = useSelector((state) => state.bdbModalComponent)
  const caseConfig = useMemo(() => CaseConfigsFactory(
    activeCase
  ).setSubTittle(), [activeCase])
  let productsNumber = `${activeCase.requestedProducts ?? '1'}`
  const modalConfig = useMemo(() => new ModalConfig(requestNumber), [requestNumber])

  useEffect(() => {
    modal.current.addEventListener('buttonAlertClicked', ModalClick)
    if (caseConfig.hasProducts) {
      radio.current.addEventListener('radioEmitter', radioButtonEvent)
    }
    dispatch(setModalParameters(modalConfig.getObject()))
    dispatch(setManagerProcess(caseConfig.optionSidebar?.item))
    dispatch(setIndexSidebar({ index: caseConfig.optionSidebar?.index }))
  }, [dispatch, modalConfig])

  const approveCase = () => {
    modalConfig.setApprove()
    dispatch(setModalParameters(modalConfig.getObject()))
    modal.current.openAlert()
  }
  const pauseCase = () => {
    modalConfig.setPause()
    dispatch(setModalParameters(modalConfig.getObject()))
    if (caseConfig.pausedModal) return dropDownModal.current.openBackdrop()
    modal.current.openAlert()
  }
  const returnCase = () => {
    modalConfig.setReturn()
    dispatch(setModalParameters(modalConfig.getObject()))
    if (caseConfig.pausedModal) return textModal.current.openBackdrop()
    modal.current.openAlert()
  }

  const radioButtonEvent = (e) => {
    productsNumber = e.detail.filter(det => det.isChecked.includes('true'))[0].value
  }
  const ModalClick = (e) => {
    const newState = e.detail?.value
    if (newState === 'Cancelar') return
    if (newState === 'Aceptar') {
      navigate(`/request-case/${type}/${process}`)
      dispatch(setOpenCase({}))
      dispatch(resetModalParameters())
      return
    }
    dispatch(updateCaseWithProductivity(newState, productsNumber, loading, modal.current))
  }

  return (
    <>
      <DropDownModal
        refCustom={dropDownModal}
        options={caseConfig.pausedModal?.options ?? []}
        confirmationClick={() => {
          ModalClick({ detail: { value: 'Pausar' } })
          dropDownModal.current.closeBackdrop()
        }}
      />
      <TextModal
        refCustom={textModal}
        confirmationClick={() => {
          ModalClick({ detail: { value: 'Devolver' } })
          textModal.current.closeBackdrop()
        }}
      />
      <bdb-ml-modal
        ref={modal}
        isClose="true"
        icon={icon}
        title-modal={title}
        options-buttons={options}
        sub-title={subTitle}
      ></bdb-ml-modal>
      <div data-cy="card-case" className="s-m-t-10 margin-auto-x _case-card s-p-8 row">
        <div className="sherpa-typography-heading-5 s-m-b-1 col-lg-12">
          {caseConfig.mainTittle.label}
          {' ' + activeCase[caseConfig.mainTittle.atrribute]}
        </div>
        <div
          data-cy="tittle-case"
          className="sherpa-typography-heading-8 col-lg-12"
        >
          {caseConfig.subTittle}
        </div>
        {caseConfig.hasDocuments && (
          <a
            className="_links-no-decoration s-m-b-6"
            href={`${urlApp}/order-docs`}
            target="_blank"
            rel="noopener noreferrer"
          >
            <button className="bdb-at-btn bdb-at-btn--link bdb-at-btn--sm">
              <span className="ico-documents"></span>
              <label>Documentos</label>
            </button>
          </a>
        )}
        <div className="col-lg-12 no-padding">
          {caseConfig.bodyCase.map((rowBody, idx) => (
            <div key={idx} className="s-p-b-3 row">
              <span className="col-lg-6 sherpa-typography-body-1 text-carbon-800">
                {`${rowBody.label}:`}
              </span>
              <span className="col-lg-6 text-right sherpa-typography-body-1 text-carbon-900">
                {activeCase[rowBody.atrribute] ? activeCase[rowBody.atrribute].replace('N/A', '-') : '-'}
              </span>
            </div>
          ))}
          {caseConfig.hasProducts && (
            <>
              <div className="s-p-b-3 row">
                <label className="col-lg-12 sherpa-typography-body-1 text-carbon-800">
                  Productos a solicitar:
                </label>
              </div>
              <div className="col-lg-5 ">
                <bdb-at-radio-button
                  ref={radio}
                  is-horizontal={'true'}
                  is-flex={true}
                  values-to-radio={productOptions(productsNumber)}
                  radioEmitter={radioButtonEvent}
                ></bdb-at-radio-button>
              </div>
            </>
          )}
        </div>
        <div className="col-lg-12 s-m-t-4 no-padding">
          <div className="row">
            <div className="col-lg-4">
              <button
                onClick={returnCase}
                className="_case-btn-action bdb-at-btn  bdb-at-btn--secondary bdb-at-btn--lg bdb-at-btn--fluid margin-auto-right"
              >
                Devolver
              </button>
            </div>
            <div className="col-lg-4">
              <button
                onClick={approveCase}
                className="_case-btn-action bdb-at-btn  bdb-at-btn--secondary bdb-at-btn--lg bdb-at-btn--fluid margin-auto"
              >
                Aprobar
              </button>
            </div>
            <div className="col-lg-4">
              <button
                onClick={pauseCase}
                className="_case-btn-action bdb-at-btn  bdb-at-btn--primary bdb-at-btn--lg bdb-at-btn--fluid margin-auto-left"
              >
                Pausar
              </button>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}
export default Case
