import React, { useEffect, useRef, useContext } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useNavigate } from 'react-router'
import { CasesStates, friendlyStatus } from '../../constants'
import { LoaderContext } from '../../Contexts/LoaderContex'
import { getItemSidebarFromProcess } from '../../helpers/mapper'
import { setCaseFoundError, setSelectedCase, setSelectedType, setSuccessUpdate } from '../../store/slices/caseManager/caseManagerSlice'
import { getCasesByRequestNumber } from '../../store/slices/caseManager/caseManagerThunks'

const processTable = [
  { colName: '', control: 'id' },
  { colName: '', control: 'id' },
  { colName: 'Proceso', control: 'text' },
  { colName: 'Fecha recibido', control: 'compound' },
  { colName: 'Estado', control: 'tag' },
  { colName: 'Gestor', control: 'text' },
  { colName: 'Fecha gestionado', control: 'compound' },
  { colName: 'Prioridad', control: 'tag' },
  { colName: '', control: 'button' }
]
const orderDisbursementProcessTable = [
  { colName: '', control: 'id' },
  { colName: '', control: 'id' },
  { colName: 'Proceso', control: 'text' },
  { colName: 'Fecha recibido', control: 'compound' },
  { colName: 'Gestor', control: 'text' },
  { colName: 'Fecha gestionado', control: 'compound' },
  { colName: 'Prioridad', control: 'tag' },
  { colName: 'Documentos', control: 'button' }
]

const timerToast = 5000
const titleReturned = 'Causal de devolución'

const SearchCaseLeader = () => {
  const loader = useContext(LoaderContext)

  const inputCase = useRef()
  const tableBtn = useRef()
  const toast = useRef()
  const reasonReturnModal = useRef()

  const navigate = useNavigate()
  const dispatch = useDispatch()

  const { caseInfo, cases, caseFoundError, successUpdate, selectedCase } = useSelector((state) => state.casesManager)

  const handleEnter = ({ target }) => {
    dispatch(getCasesByRequestNumber(target.value, loader))
  }
  const handleChangeInput = () => {
    dispatch(setCaseFoundError(false))
  }
  const handleBtnClick = ({ detail }) => {
    if (detail.data.Boton === 'Ver ›') return window.open('/order-docs', '_blank')
    const path = detail.data.Boton === 'Reasignar ›' ? 'reassign' : detail.data.Boton === 'Priorizar ›' ? 'prioritize' : ''
    const { original } = detail.data
    dispatch(setSelectedCase(JSON.parse(original)))
    navigate(`/leader/disbursement/search-case/${path}`)
  }
  const getMessage = () => {
    return selectedCase.reqStatus === 'PENDING' ? 'La priorización fue exitosa' : 'La reasignación fue exitosa'
  }
  const isOrderDisbursementReturned = (_caseInfo) => {
    const itemSideBar = getItemSidebarFromProcess(_caseInfo?.process).item
    return itemSideBar?.slug === 'order-disbursement'
              && _caseInfo?.reqStatus === CasesStates.REJECTED
  }
  const selectColsTable = (_caseInfo) => {
    return isOrderDisbursementReturned(_caseInfo) ? orderDisbursementProcessTable : processTable
  }

  useEffect(() => {
    dispatch(setSelectedType({ label: 'Buscar caso desembolso centralizado' }))
    const { current: currentInput } = inputCase
    const { current: currentBtn } = tableBtn
    const _listeners = () => {
      currentInput.addEventListener('atInputEnterKey', handleEnter)
      currentInput.addEventListener('atInputChanged', handleChangeInput)
    }
    if (currentInput) _listeners()

    return () => {
      if (currentInput) {
        currentInput.removeEventListener('atInputEnterKey', handleEnter)
        currentInput.removeEventListener('atInputChanged', handleChangeInput)
      }
      if (currentBtn) {
        currentBtn.removeEventListener('buttonClick', handleBtnClick)
      }
    }
  }, [])
  useEffect(() => {
    if (successUpdate) {
      toast.current.show()
      dispatch(setSuccessUpdate(false))
    }
  }, [dispatch, successUpdate])

  useEffect(() => {
    const { current: currentBtn } = tableBtn
    const _listeners = () => {
      currentBtn.addEventListener('buttonClick', handleBtnClick)
    }
    if (currentBtn) _listeners()
  }, [cases])

  return (
    <div>
      <>
      <bdb-ml-terms
        data-cy="reason-return-modal"
        ref={reasonReturnModal}
        titleterms={titleReturned}
        terms={caseInfo.reasonReturn}
      ></bdb-ml-terms>
      <bdb-at-toast
        type="SUCCESS"
        time={timerToast}
        ref={toast}
        data-cy="toast-prioritize"
        message={getMessage()}>
      </bdb-at-toast>
        <div className="input-prioritize-w no-padding s-m-t-4 ">
          <bdb-at-input label="Busca, prioriza o reasigna un caso" placeholder="Escribe el Nº de caso" type="TEXT"
            data-cy="input-case"
            status={caseFoundError ? 'ERROR' : 'HELP'} ref={inputCase}
            message={caseFoundError ? 'Número de caso incorrecto' : 'Presiona enter para realizar la búsqueda'}
            view-mode></bdb-at-input>
        </div>

        {
          cases.length !== 0 &&
          <>
          <div className='s-m-t-8'>
            {
              caseInfo.reqStatus === 'PAUSED' &&
              (
                <>
                <div className='row'>
                  <bdb-at-interactive-tag
                  style={{ display: 'inline-block' }}
                  type="WARNING"
                  text="Pausado"
                  ></bdb-at-interactive-tag>
                  &nbsp;
                  <div className='text-carbon-800'> {caseInfo.reasonReturn}</div>
                </div>
                </>
              )

            }
            <div className="_prioritizeLeader-card-container s-p-x-6">
              <span
                data-cy="tittle"
                className="sherpa-typography-title-2 col-lg-3"
              >
                Caso {caseInfo.requestNumber}
              </span>
              <div className='vertical-line-separator'></div>
              <div className="_indicatorsLeader-box-totals">
              <div className="_indicatorsLeader-col">
                <label className="sherpa-typography-label-1">
                  Documento:
                  <span
                    className='s-m-t-2'
                    data-cy="summary-document">
                    {caseInfo.document}
                  </span>
                </label>
              </div>
              <div className="_indicatorsLeader-col">
                <label className="sherpa-typography-label-1">
                  Producto:
                  <span
                    className='s-m-t-2'
                    data-cy="summary-product">
                    {caseInfo.product ?? '-'}
                  </span>
                </label>
              </div>
            </div>
            </div>
            <div className="s-m-t-4 _flex-j-space">
              <div style={isOrderDisbursementReturned(caseInfo) ? { width: '84%' } : { width: '100%' }}>
                <bdb-ml-dynamic-table
                  ref={tableBtn}
                  data-cy="bdb-ml-dynamic-table"
                  column-table={JSON.stringify(selectColsTable(caseInfo))}
                  row-table={JSON.stringify(cases)}
                ></bdb-ml-dynamic-table>
              </div>
              {isOrderDisbursementReturned(caseInfo)
                &&
                <div
                  data-cy="card-order-returned"
                  className='_prioritizeLeader-card-order-returned'>
                  <div className='sherpa-typography-title-3'>Estado</div>
                  <span className='sherpa-typography-title-3 _prioritizeLeader-card-status'>{friendlyStatus[caseInfo?.reqStatus]?.text}</span>
                  <button
                    className='s-m-t-2 bdb-at-btn bdb-at-btn--secondary bdb-at-btn--sm'
                    onClick={() => reasonReturnModal.current.openModal()}
                  >Ver causal</button>
                </div>
              }
            </div>
          </div>
          </>
        }
      </>
    </div>
  )
}

export default SearchCaseLeader
