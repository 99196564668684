import React, { useRef, useEffect } from 'react'
import { useDispatch } from 'react-redux'
import { startCountDown, startLogin } from '../../store/slices/sessionRedux/sessionSlice'
import { useAuthenticator } from '@aws-amplify/ui-react'

const CloseModalcomponent = () => {
  const dispatch = useDispatch()
  const modal = useRef()
  const { signOut } = useAuthenticator((context) => [context.signOut])

  const handleButton = (event) => {
    if (event.detail.id === '1') {
      closeSession()
    } else {
      dispatch(startCountDown(false))
    }
  }

  const closeSession = () => {
    dispatch(startLogin(false))
    dispatch(startCountDown(false))
    signOut()
  }

  useEffect(() => {
    const { current } = modal
    current.openAlert()
    current.addEventListener('buttonAlertClicked', handleButton)
    current.addEventListener('countDownTimerOver', closeSession)
  }, [])

  return (
    <>
      <bdb-ml-modal
        data-cy="session-timeout-modal"
        ref={modal}
        isClose="true"
        options-buttons='[{"id":"0","value":"¡Sigo aquí!"},
                                    {"id":"1","value":"Cerrar sesión"}]'
        title-modal="Tu sesión se cerrará"
        sub-title="Estás a punto de superar el tiempo máximo de inactividad."
        is-timer="true"
        time = {120}
      ></bdb-ml-modal>
    </>
  )
}

export default CloseModalcomponent
