import { createSlice } from '@reduxjs/toolkit'

const initialState = {
  guaranteeNumber: null,
  guaranteeInfo: {}
}

export const reserveSlice = createSlice({
  name: 'RESERVE',
  initialState,
  reducers: {
    setGuaranteeNumer: (state, action) => {
      state.guaranteeNumber = action.payload
    },
    setGuaranteeInfo: (state, action) => {
      state.guaranteeInfo = { ...action.payload }
    },
    updateByKeyGuaranteeInfo: (state, action) => {
      state.guaranteeInfo = {
        ...state.guaranteeInfo,
        [action.payload.key]: action.payload.value
      }
    },
    resetReserveInfo: (state) => {
      state.guaranteeNumber = initialState.guaranteeNumber
      state.guaranteeInfo = { ...initialState.guaranteeInfo }
    }
  }
})

// Action creators are generated for each case reducer function
export const {
  setGuaranteeNumer,
  setGuaranteeInfo,
  updateByKeyGuaranteeInfo,
  resetReserveInfo
} = reserveSlice.actions

export default reserveSlice.reducer
