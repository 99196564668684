import React, { Fragment, useRef, useEffect, useCallback } from 'react'
import PropTypes from 'prop-types'
import { useDispatch, useSelector } from 'react-redux'
import { setTextAreaValue } from '../../store/slices/bdbModalComponent/bdbModalSlice'
import CompoundModal from '../CompoundModal/CompoundModal'

const placeholder = 'Escribe la causal de devolución.'

const TextModal = ({ refCustom, confirmationClick }) => {
  const textArea = useRef()
  const dispatch = useDispatch()

  const { title, textAreaValue } = useSelector((state) => state.bdbModalComponent)

  const onChange = useCallback(
    (e) => {
      dispatch(setTextAreaValue(e.detail?.text))
    },
    [dispatch]
  )

  const handleClickCancel = (e) => {
    refCustom.current.closeBackdrop()
  }
  useEffect(() => {
    const { current } = textArea
    current.addEventListener('atTextAreaChanged', onChange)

    return () => {
      current.removeEventListener('atTextAreaChanged', onChange)
    }
  }, [onChange])

  return (
    <Fragment>
        <CompoundModal
            backdrop={refCustom}
        >
            <div className='_textModal-box'>
                <span className="ico-information s-m-b-6" style={{ display: 'block' }}>
                    <span className="path1"></span>
                    <span className="path2"></span>
                    <span className="path3"></span></span>
                <span className='sherpa-typography-heading-6 s-m-b-8' style={{ display: 'block' }}>{title}</span>
                <bdb-at-text-area
                    ref={textArea}
                    value={textAreaValue}
                    placeholder={placeholder}
                    status="ENABLED"
                    message=""
                    label=""
                    limit-of-characters={2000}
                    height='140px'
                    auto-resize="false"
                />
                <div
                    className='_flex-justify-end s-m-t-7'
                    style={{ gap: '16px' }}
                >
                    <button
                        style={{ width: '152px' }}
                        onClick={handleClickCancel}
                        className="bdb-at-btn bdb-at-btn--secondary bdb-at-btn--lg"
                    >
                        Cancelar
                    </button>
                    <button
                        style={{ width: '152px' }}
                        onClick={confirmationClick}
                        className="bdb-at-btn bdb-at-btn--primary bdb-at-btn--lg"
                        disabled={!textAreaValue}
                    >
                        Devolver
                    </button>
                </div>
            </div>
        </CompoundModal>
    </Fragment>
  )
}

export default TextModal

TextModal.propTypes = {
  refCustom: PropTypes.object.isRequired,
  confirmationClick: PropTypes.func
}
