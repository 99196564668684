/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const getCustomByManagement = /* GraphQL */ `
  query GetCustomByManagement(
    $management: String!
    $process: String!
    $reqStatus: String!
    $reqUser: String!
    $beginDate: String!
    $limit: Int
  ) {
    getCustomByManagement(
      management: $management
      process: $process
      reqStatus: $reqStatus
      reqUser: $reqUser
      beginDate: $beginDate
      limit: $limit
    ) {
      id
      currentProcess
      requestNumber
      typeDocument
      document
      file
      flow
      dateAssignment
      hourAssignment
      amountApproved
      management
      process
      reqStatus
      fileOrder
      priority
      reqUser
      line
      product
      assignedTo
      comercialProduct
      hasLibrance
      libranzaCode
      step
      seenGoodPagaduria
      createdAt
      updatedAt
      leaderOwner
      numberControl
      creditProject
      reasonReturn
      requestedProducts
      area
      idProductivity
      beginDate
      endDate
      owner
    }
  }
`;
export const getCustomByCovenance = /* GraphQL */ `
  query GetCustomByCovenance(
    $management: String!
    $process: String!
    $reqStatus: String!
    $reqUser: String!
    $beginDate: String!
    $limit: Int
  ) {
    getCustomByCovenance(
      management: $management
      process: $process
      reqStatus: $reqStatus
      reqUser: $reqUser
      beginDate: $beginDate
      limit: $limit
    ) {
      id
      currentProcess
      requestNumber
      typeDocument
      document
      file
      flow
      dateAssignment
      hourAssignment
      amountApproved
      management
      process
      reqStatus
      fileOrder
      priority
      reqUser
      line
      product
      assignedTo
      comercialProduct
      hasLibrance
      libranzaCode
      step
      seenGoodPagaduria
      createdAt
      updatedAt
      leaderOwner
      numberControl
      creditProject
      reasonReturn
      requestedProducts
      area
      idProductivity
      beginDate
      endDate
      owner
    }
  }
`;
export const searchCases = /* GraphQL */ `
  query SearchCases(
    $filter: SearchableCaseFilterInput
    $sort: [SearchableCaseSortInput]
    $limit: Int
    $nextToken: String
    $from: Int
    $aggregates: [SearchableCaseAggregationInput]
  ) {
    searchCases(
      filter: $filter
      sort: $sort
      limit: $limit
      nextToken: $nextToken
      from: $from
      aggregates: $aggregates
    ) {
      items {
        id
        currentProcess
        requestNumber
        typeDocument
        document
        file
        flow
        dateAssignment
        hourAssignment
        amountApproved
        management
        process
        reqStatus
        fileOrder
        priority
        reqUser
        line
        product
        assignedTo
        comercialProduct
        hasLibrance
        libranzaCode
        step
        seenGoodPagaduria
        createdAt
        updatedAt
        leaderOwner
        numberControl
        creditProject
        reasonReturn
        requestedProducts
        area
        idProductivity
        beginDate
        endDate
        owner
      }
      nextToken
      total
      aggregateItems {
        name
        result {
          ... on SearchableAggregateScalarResult {
            value
          }
          ... on SearchableAggregateBucketResult {
            buckets {
              key
              doc_count
            }
          }
        }
      }
    }
  }
`;
export const getCase = /* GraphQL */ `
  query GetCase($requestNumber: String!, $createdAt: AWSDateTime!, $id: ID!) {
    getCase(requestNumber: $requestNumber, createdAt: $createdAt, id: $id) {
      id
      currentProcess
      requestNumber
      typeDocument
      document
      file
      flow
      dateAssignment
      hourAssignment
      amountApproved
      management
      process
      reqStatus
      fileOrder
      priority
      reqUser
      line
      product
      assignedTo
      comercialProduct
      hasLibrance
      libranzaCode
      step
      seenGoodPagaduria
      createdAt
      updatedAt
      leaderOwner
      numberControl
      creditProject
      reasonReturn
      requestedProducts
      area
      idProductivity
      beginDate
      endDate
      owner
    }
  }
`;
export const listCases = /* GraphQL */ `
  query ListCases(
    $requestNumber: String
    $createdAtId: ModelCasePrimaryCompositeKeyConditionInput
    $filter: ModelCaseFilterInput
    $limit: Int
    $nextToken: String
    $sortDirection: ModelSortDirection
  ) {
    listCases(
      requestNumber: $requestNumber
      createdAtId: $createdAtId
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      sortDirection: $sortDirection
    ) {
      items {
        id
        currentProcess
        requestNumber
        typeDocument
        document
        file
        flow
        dateAssignment
        hourAssignment
        amountApproved
        management
        process
        reqStatus
        fileOrder
        priority
        reqUser
        line
        product
        assignedTo
        comercialProduct
        hasLibrance
        libranzaCode
        step
        seenGoodPagaduria
        createdAt
        updatedAt
        leaderOwner
        numberControl
        creditProject
        reasonReturn
        requestedProducts
        area
        idProductivity
        beginDate
        endDate
        owner
      }
      nextToken
    }
  }
`;
export const getCovenance = /* GraphQL */ `
  query GetCovenance($id: ID!) {
    getCovenance(id: $id) {
      id
      code
      name
      status
      users
      createdAt
      updatedAt
    }
  }
`;
export const listCovenances = /* GraphQL */ `
  query ListCovenances(
    $filter: ModelCovenanceFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listCovenances(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        code
        name
        status
        users
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const casesByMangament = /* GraphQL */ `
  query CasesByMangament(
    $management: String!
    $processReqStatusPriorityFileOrderDateAssignmentHourAssignmentCreatedAt: ModelCaseByManagementCompositeKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelCaseFilterInput
    $limit: Int
    $nextToken: String
  ) {
    casesByMangament(
      management: $management
      processReqStatusPriorityFileOrderDateAssignmentHourAssignmentCreatedAt: $processReqStatusPriorityFileOrderDateAssignmentHourAssignmentCreatedAt
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        currentProcess
        requestNumber
        typeDocument
        document
        file
        flow
        dateAssignment
        hourAssignment
        amountApproved
        management
        process
        reqStatus
        fileOrder
        priority
        reqUser
        line
        product
        assignedTo
        comercialProduct
        hasLibrance
        libranzaCode
        step
        seenGoodPagaduria
        createdAt
        updatedAt
        leaderOwner
        numberControl
        creditProject
        reasonReturn
        requestedProducts
        area
        idProductivity
        beginDate
        endDate
        owner
      }
      nextToken
    }
  }
`;
export const casesByUser = /* GraphQL */ `
  query CasesByUser(
    $reqUser: String!
    $reqStatusCreatedAt: ModelCaseByUserCompositeKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelCaseFilterInput
    $limit: Int
    $nextToken: String
  ) {
    casesByUser(
      reqUser: $reqUser
      reqStatusCreatedAt: $reqStatusCreatedAt
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        currentProcess
        requestNumber
        typeDocument
        document
        file
        flow
        dateAssignment
        hourAssignment
        amountApproved
        management
        process
        reqStatus
        fileOrder
        priority
        reqUser
        line
        product
        assignedTo
        comercialProduct
        hasLibrance
        libranzaCode
        step
        seenGoodPagaduria
        createdAt
        updatedAt
        leaderOwner
        numberControl
        creditProject
        reasonReturn
        requestedProducts
        area
        idProductivity
        beginDate
        endDate
        owner
      }
      nextToken
    }
  }
`;