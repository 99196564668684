import { Cache } from 'aws-amplify'
import { v4 as uuidv4 } from 'uuid'

let isFinalEvent = false
let htmlPage = []

export const Interactions = (funnelTmp, funnel, event, kinesis, Auth, beginTime) => {
  console.log('event:', event.type)
  setTimeout(() => {
    document.body.querySelectorAll('*').forEach(function (node) {
      if (node.id === 'root') {
        htmlPage.push(node.innerHTML)
      }
    })
  }, 1000)
  funnelTmp.funnelId = funnel.length > 0 ? funnelTmp.funnelId : uuidv4()
  funnelTmp.journey = event.target.baseURI ? event.target.baseURI : event.target.document.baseURI
  const link = funnelTmp.journey.split('/')
  link.splice(0, 3)
  funnelTmp.flow = ''
  link.forEach(element => {
    funnelTmp.flow = funnelTmp.flow + (funnelTmp.flow === '' ? '' : '-') + element
  })
  funnelTmp.step = event.target.className ? event.target.className : 'main-screen'
  funnelTmp.idElement = event.target.className ? event.target.className : ''
  funnelTmp.valueElement = event.target.outerHTML ? event.target.outerHTML : ''
  funnelTmp.htmlPage = htmlPage[0]
  funnelTmp.eventDate = (new Date()).toISOString()
  funnelTmp.eventType = event.type
  isFinalEvent =
        event.type === 'unload' ||
        (
          event.type === 'click' &&
            (
              event.target.outerText === 'Guardar cambios' ||
              funnelTmp.idElement.includes('_case-btn-action bdb-at-btn') ||
              event.target.outerHTML.includes('btn-request')
            )
        )
  if (funnel.length === 0) {  beginTime = (new Date()).getTime() }
  if (isFinalEvent) {
    const endTime = (new Date()).getTime()
    funnelTmp.funnelTime = (endTime - beginTime) / 3600000
  }
  const record = {
    Data: JSON.stringify(funnelTmp),
    PartitionKey: 'partition-' + Auth.Credentials?._credentials?.identityId
  }
  funnel.push(record)
  Cache.setItem('KinesisFunnel', funnel)
  htmlPage = []

  if (isFinalEvent) {
    kinesis.putRecords({
      Records: funnel,
      StreamName: 'userInteractions'
    }, function (err, data) {
      if (err) {
        console.error(err)
      } else {
        console.log('success put Kinesis', data)
      }
    })
    funnel = []
    Cache.removeItem('KinesisFunnel')
  }
  return  { funnel, funnelTmp, beginTime }
}
