import { itemsSidebar, leaderOptions } from '../../constants'
import React, { useRef, useEffect, useCallback } from 'react'
import { useLocation, useNavigate, useParams } from 'react-router'
import { useDispatch, useSelector } from 'react-redux'
import { setIndexSidebar, setManagerProcess } from '../../store/slices/caseManager/caseManagerSlice'

export const Sidebar = ({ context = 'manager' }) => {
  const { type, process } = useParams()
  const { pathname } = useLocation()
  const { managerProcess, configSidebar } = useSelector((state) => state.casesManager)
  const { label } = managerProcess
  const sidebarItems =
    context === 'leader'
      ? JSON.stringify(leaderOptions[type])
      : JSON.stringify(itemsSidebar[type])
  const dispatch = useDispatch()
  const navigate = useNavigate()

  const sidebar = useRef()

  const handleClick = useCallback(
    (e) => {
      const { item } = e.detail
      if (item) {
        navigate(`${type}/${item.slug}`)
        dispatch(setManagerProcess(item))
      }
    },
    [dispatch, navigate, type]
  )

  const getSidebarProcess = () => {
    const items = JSON.parse(sidebarItems)
    return {
      item: items.find(
        (el) => el.slug === process || pathname.includes(el.slug)
      ),
      index: items.findIndex(
        (el) => el.slug === process || pathname.includes(el.slug)
      )
    }
  }

  useEffect(() => {
    const { current } = sidebar
    current.addEventListener('sidebarEmitter', handleClick)
    return () => {
      if (current) current.removeEventListener('sidebarEmitter', handleClick)
    }
  }, [handleClick])

  useEffect(() => {
    if (!label && !!sidebarItems) {
      const responseSidebarProcess = getSidebarProcess()
      dispatch(setIndexSidebar({ index: responseSidebarProcess.index }))
      if (!responseSidebarProcess) {
        navigate('/home')
        return
      }
      dispatch(setManagerProcess(responseSidebarProcess.item))
    }
  }, [dispatch])

  return (
    <div className="sidebar col-lg-auto">
      <bdb-ml-sidebar
        data-cy="sidebar"
        item-selected={configSidebar.index}
        items-sidebar={sidebarItems}
        ref={sidebar}
      ></bdb-ml-sidebar>
    </div>
  )
}
export default Sidebar
