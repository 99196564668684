import React from 'react'
import { Amplify } from 'aws-amplify'
import { AWSIoTProvider } from '@aws-amplify/pubsub'
import awsExports from './aws-exports'
import '@aws-amplify/ui-react/styles.css'
import { AppRouter } from './routes/AppRouter'
import { Authenticator } from '@aws-amplify/ui-react'
import './styles/main.scss'

Amplify.configure({
  ...awsExports,
  oauth: {
    ...awsExports.oauth,
    redirectSignIn: process.env.REACT_APP_PROJECT_URL,
    redirectSignOut: process.env.REACT_APP_PROJECT_URL,
    responseType: 'code'
  },
  API: {
    endpoints: [
      {
        name: 'Guarantee',
        endpoint: process.env.REACT_APP_FNG_API_ENDPOINT
      },
      {
        name: 'ApiGatewayApi',
        endpoint: process.env.REACT_APP_IDM_API_ENDPOINT
      },
      {
        name: 'microservice',
        endpoint: process.env.REACT_APP_CASE_INSERTER
      },
      {
        name: 'SelfAssign',
        endpoint: process.env.REACT_APP_SELF_ASSIGN_API_ENDPOINT
      },
      {
        name: 'ApiLoginAdviser',
        endpoint: process.env.REACT_APP_LOGIN_ADVISER_ENDPOINT
      }
    ]
  }
})
Amplify.addPluggable(
  new AWSIoTProvider({
    aws_pubsub_region: 'us-east-1',
    aws_pubsub_endpoint:
      `wss://${process.env.REACT_APP_IOT_ENDPOINT}/mqtt`
  })
)

function App () {
  return (
    <Authenticator.Provider>
      <AppRouter />
    </Authenticator.Provider>
  )
}

export default App
