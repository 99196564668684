import { Auth } from 'aws-amplify'
import AWS from 'aws-sdk/dist/aws-sdk-react-native'
import { AWS_REGION } from '../constants'

export const stepFunctions = async (params) => {
  await Auth.currentCredentials()
  const options = {
    region: AWS_REGION,
    ...Auth.Credentials._credentials
  }
  const _stepFunctions = new AWS.StepFunctions(options)

  return new Promise((resolve, reject) => {
    _stepFunctions.startExecution(params, (err, data) => {
      if (err) reject(err)
      else resolve(data)
    })
  })
}
