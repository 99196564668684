export const AWS_REGION = 'us-east-1'
// TO DO: IMPLEMENT PIPELINE
export const stateMachineArn =
  'arn:aws:states:us-east-1:561457267430:stateMachine:CasesLoaderStateMachine-LA7M0K0n9Iz0'
export const tZColombia = 'America/Bogota'
export const DISBURSEMENT_MANAGEMENT = 'Gcia. Op. Desembolsos Integrales'
export const dataHub = ['showModalRenewToken', 'closeSession']

export const CasesStates = {
  OPEN: 'OPEN',
  PAUSED: 'PAUSED',
  REJECTED: 'REJECTED',
  APPROVED: 'APPROVED',
  PENDING: 'PENDING'
}

export const friendlyStates = {
  APPROVED: 'Aprobados',
  REJECTED: 'Devueltos',
  PAUSED: 'Pausados',
  OPEN: 'Asignados',
  PENDING: 'Pendientes'
}
export const caseStatesTranslate = {
  Aprobar: CasesStates.APPROVED,
  Pausar: CasesStates.PAUSED,
  Devolver: CasesStates.REJECTED
}
export const APPROVED_STATUS = 'APPROVED'
export const REJECTED_STATUS = 'REJECTED'
export const REPLACED_STATUS = 'REPLACED'
export const REALIZADA_STATUS = 'REA'
export const RECHAZADA_STATUS = 'RER'
export const REPLACED_PRODUCT = 'EMP080'
export const HOUSING = 'housing'
export const LIBRANCES = 'librances'
export const DISBURSEMENT = 'groupDisbursement'
export const VIVE = 'groupVive'
export const SELF_ASSIGN_MANAGEMENT = {
  LideresDesembolsoV: HOUSING,
  VerificadorViviendaDesembolso: HOUSING,
  LideresDesembolsoLib: LIBRANCES,
  VerificadorLibranzasDesembolso: LIBRANCES,
  LideresDesembolsos: DISBURSEMENT,
  GestoresDesembolsos: DISBURSEMENT,
  LideresVive: VIVE,
  GestoresVive: VIVE
}
export const friendlyStatus = {
  APPROVED: {
    type: 'success', text: 'Aprobado'
  },
  REJECTED: {
    type: 'error', text: 'Devuelto'
  },
  PAUSED: {
    type: 'warning', text: 'Pausado'
  },
  OPEN: {
    type: 'info', text: 'Abierto'
  },
  PENDING: {
    type: 'disabled', text: 'Pendiente'
  }
}

export const friendlyPriority = {
  1: {
    type: 'info', text: 'Alta'
  },
  2: {
    type: 'disabled', text: 'Media'
  },
  3: {
    type: 'text', text: ''
  }
}

export const itemsSidebar = {
  defaultManagement: DISBURSEMENT_MANAGEMENT,
  reviewDoc: [
    {
      icon: 'ico-bank-references',
      label: 'Revisión doc. PN',
      process: 'Persona Natural - Revisión Documental',
      description: 'Revisión Documental PN',
      slug: 'revision-documental-pn',
      isActive: 'true'
    },
    {
      icon: 'ico-document-check',
      label: 'Revisión Doc. PJ',
      process: 'Persona Jurídica - Rev. Doc. F Único',
      description: 'Revisión Documental PJ',
      slug: 'revision-documental-pj',
      isActive: 'true'
    },
    {
      icon: 'ico-cash-bag',
      label: 'Desembolso Comercial',
      process: 'Revisión Mantiz',
      description: 'Desembolso Comercial',
      slug: 'desembolso-comercial',
      isActive: 'true'
    },
    {
      icon: 'ico-liquidez',
      label: 'Captura Vivienda',
      process: 'Captura Crédito Directo',
      description: 'Captura Vivienda',
      slug: 'captura-vivienda',
      isActive: 'true'
    },
    {
      icon: 'ico-accounting-document',
      label: 'Orden de Desembolso',
      process: 'Revisión orden de desembolso',
      description: 'Orden de Desembolso',
      slug: 'order-disbursement',
      isActive: 'true'
    }
  ],
  groupDisbursement: [
    {
      icon: 'ico-coins',
      label: 'Consumo',
      process: 'Desembolso - Consumo',
      description: 'Consumo',
      slug: 'consumer',
      isActive: 'true'
    },
    {
      icon: 'ico-libranzas',
      label: 'Libranza',
      process: 'Desembolso Con VoBo / Sin VoBo - Libranza',
      description: 'Libranza',
      slug: 'draft',
      isActive: 'true'
    },
    // DESHABILITADO POR PETICIÓN DE PO
    /*     {
      icon: 'ico-checklist',
      label: 'Libranza VoBo Pagaduría',
      process: 'VoBo Pagaduría - Libranzas',
      description: 'Libranza VoBo Pagaduría',
      slug: 'draft-vobo',
      isActive: 'true'
    } */
    {
      icon: 'ico-coin-bills',
      label: 'Grupo VIVE Desembolso',
      process: 'Desembolso - VIVE',
      description: 'Grupo VIVE Desembolso',
      slug: 'vive-disbursement',
      isActive: 'true'
    },
    {
      icon: 'ico-home',
      label: 'Vivienda Desembolso',
      process: 'Desembolso Crédito Directo',
      description: 'Vivienda Desembolso',
      slug: 'housing-disbursement',
      isActive: 'true'
    }
  ],
  reserve: [
    {
      label: 'Solicitar reserva FNG',
      description: 'Solicitar reserva FNG',
      slug: 'search'
    }
  ]
}

export const leaderOptions = {
  disbursement: [
    {
      icon: 'ico-dashboard-layout',
      label: 'Indicadores',
      description: 'Indicadores desembolso centralizado',
      slug: 'indicators',
      isActive: 'true'
    },
    {
      icon: 'ico-read-search',
      label: 'Buscar caso',
      description: 'Buscar caso desembolso centralizado',
      slug: 'search-case',
      isActive: 'false'
    },
    {
      icon: 'ico-settings',
      label: 'Confi. Libranzas',
      description: 'Confi. Libranzas',
      slug: 'settings',
      isActive: 'false'
    }
  ]
}
export const processes = [
  {
    label: 'Gestionar caso Desembolso',
    type: 'groupDisbursement',
    icon: 'ico-form-pencil'
  },
  {
    label: 'Gestionar caso Revisión Documental',
    type: 'reviewDoc',
    icon: 'ico-form-check'
  },
  {
    label: 'Solicitar reserva FNG',
    type: 'reserve',
    icon: 'ico-cash-bag'
  }
]
export const modalPaused = (number) => {
  return {
    title: `¿Estás seguro de pausar el caso ${number}?`,
    icon: 'information',
    options: '[{"id":"0","value":"Pausar"},{"id":"1","value":"Cancelar"}]'
  }
}
export const modalApproved = (number) => {
  return {
    title: `¿Estás seguro de aprobar el caso ${number}?`,
    icon: 'information',
    options: '[{"id":"0","value":"Aprobar"},{"id":"1","value":"Cancelar"}]'
  }
}
export const modalReturned = (number) => {
  return {
    title: `¿Estás seguro de devolver el caso ${number}?`,
    icon: 'information',
    options: '[{"id":"0","value":"Devolver"},{"id":"1","value":"Cancelar"}]'
  }
}

export const modalOpenCases = (number) => {
  return {
    title: '',
    subTitle: `<span>Es necesario que <b>pauses</b>, <b>apruebes</b> o <b>devuelvas</b> el caso ${number} para que puedas retomar otro caso</span>`,
    icon: 'information',
    options: '[{"id":"0","value":"Aceptar"}]'
  }
}

export const fieldsPausedCases = (pausedCase) => {
  return pausedCase.map(item => ({
    id: item.id,
    Simple0: `${item.requestNumber}`,
    Simple1: item.document ? item.document.replace('N/A', '-') : '-',
    Simple2: item.product ? item.product.replace('N/A', '-') : '-',
    Simple3: `${item.process}`,
    Simple4: `${item.dateAssignment}`,
    Simple5: `${item.hourAssignment}`,
    Simple6: item.reqStatus === 'PAUSED' ? 'Pausado' : 'Abierto',
    Boton7: 'Retomar >'
  }))
}
