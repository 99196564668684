import React from 'react'
import { Routes, Route } from 'react-router-dom'
import IndicatorsLeader from '../pages/IndicatorsLeader/IndicatorsLeader'
import LeaderLayout from '../pages/LeaderLayout/LeaderLayout'
import PrioritizeReassignCaseLeader from '../pages/PrioritizeReassignCaseLeader/PrioritizeReassignCaseLeader'
import SearchCaseLeader from '../pages/SearchCaseLeader/SearchCaseLeader'

export const LeaderRoutes = () => {
  return (
    <Routes>
      <Route path="/leader" element={<LeaderLayout />}>
        <Route path=":type/indicators" element={<IndicatorsLeader />} />
        <Route path=":type/search-case" element={<SearchCaseLeader/>} />
        <Route path=":type/search-case/prioritize" element={<PrioritizeReassignCaseLeader/>} />
        <Route path=":type/search-case/reassign" element={<PrioritizeReassignCaseLeader/>} />
      </Route>
    </Routes>
  )
}
