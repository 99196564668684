
import React, { useContext, useMemo, useRef } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { stateMachineArn } from '../../constants'
import { LoaderContext } from '../../Contexts/LoaderContex'
import { stepFunctions } from '../../services/stepFunctions'
import { setModalParameters } from '../../store/slices/bdbModalComponent/bdbModalSlice'
import { setMessageLoader } from '../../store/slices/loader/loaderSlice'
import UploadFile from '../UploadFileComponent/UploadFile'
import { getHeaders } from '../../helpers/utils'
import { getLoginAdviser } from '../../services/loginAdviserService'
import { Auth } from 'aws-amplify'

const successModal = (nameFile) => ({
  icon: 'success',
  title: '¡Listo!',
  subTitle: `El archivo ${nameFile} se subió con éxito.`
})
const genericErrorModal = () => ({
  icon: 'error',
  title: 'Lo sentimos',
  subTitle: 'Se ha presentado un error con la carga del archivo'
})
const formatNameErrorModal = () => ({
  icon: 'error',
  title: 'Lo sentimos',
  subTitle: 'El nombre o el formato del archivo es incorrecto.'
})
const nameFileErrorModal = () => ({
  icon: 'error',
  title: 'Lo sentimos',
  subTitle: 'este archivo ya fue cargado anteriormente'
})

const SubHeader = () => {
  const dispatch = useDispatch()
  const modal = useRef()
  const folderS3 = 'AutoassignFiles/'
  const nameUser = useMemo(() => sessionStorage.getItem('nameUser'), [])
  const loading = useContext(LoaderContext)
  const identityId = Auth.Credentials?._credentials?.identityId
  const { icon, title, subTitle } = useSelector(
    (state) => state.bdbModalComponent
  )

  const startStateMachine = async (e, nameFile) => {
    const headers = await getHeaders()
    await stepFunctions({
      stateMachineArn,
      input: JSON.stringify({
        headers,
        file_name: `protected/${folderS3}${identityId}/${e.key}`.replace(
          '.csv',
          ''
        )
      })
    })
      .then(() => dispatch(setModalParameters(successModal(nameFile))))
      .catch((err) => {
        console.error(err)
        dispatch(setModalParameters(genericErrorModal()))
      })
      .finally(() => {
        loading.closeLoader()
        modal.current.openAlert()
      })
  }
  const handleRedirectApp = () => {
    dispatch(getLoginAdviser(loading))
  }

  return (
    <>
      <bdb-ml-modal
        data-cy="modal-upload"
        ref={modal}
        isClose="false"
        icon={icon}
        options-buttons='[{"id":"0","value":"Aceptar"}]'
        title-modal={title}
        sub-title={subTitle}
      ></bdb-ml-modal>
      <div className="_flex-j-space">
        <span className="sherpa-typography-title-1">
          Hola, {nameUser}
        </span>
        <div className="_flex-d-flex" style={{ width: '460px', gap: '16px' }}>
          <UploadFile
            customNameFile={(nameFile) => {
              loading.openLoader()
              dispatch(setMessageLoader('Cargando archivo 0%'))
              return `${nameFile}`
            }}
            prefix={folderS3}
            onFinishUpload={(e, b) => {
              startStateMachine(e, b)
            }}
            onProgressUpload={(progress) => {
              dispatch(
                setMessageLoader(
                  `Cargando archivo ${Math.trunc(
                    (progress.loaded / progress.total) * 100
                  )}%`
                )
              )
            }}
            onErrorUpload={(err) => {
              console.error(err)
              const errorModal =
                err.message === 'nameFormat'
                  ? formatNameErrorModal()
                  : (err.message === 'repeatedName' ? nameFileErrorModal() : genericErrorModal())
              dispatch(setModalParameters(errorModal))
              loading.closeLoader()
              modal.current.openAlert()
            }}
            acceptFiles={['GPBM', 'GPOLI', 'CPCV', 'GOPTC']}
          />
          <button
            className="bdb-at-btn bdb-at-btn--secondary bdb-at-btn--sm"
            style={{ width: '30%' }}
          >
            Novedades
          </button>
          <button
            className="bdb-at-btn bdb-at-btn--secondary bdb-at-btn--sm"
            style={{ width: '30%' }}
            data-cy="redirect-app"
            onClick={() => handleRedirectApp()}
          >
            Gestión diaria
          </button>
        </div>
      </div>
    </>
  )
}

export default SubHeader
