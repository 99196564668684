import React, {
  useContext,
  useEffect,
  useRef,
  useState,
  useCallback
} from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useNavigate } from 'react-router'
import { LoaderContext } from '../../Contexts/LoaderContex'
import { setInputParameters } from '../../store/slices/bdbInputComponent/bdbInputComponentSlice'
import {
  resetLoader,
  setMessageLoader,
  setRouteBack
} from '../../store/slices/loader/loaderSlice'
import { setGuaranteeNumer } from '../../store/slices/reserve/reserveSlice'
import { queryGuarantee } from '../../store/slices/reserve/reserveThunk'

const lengthError = 'El número de garantía debe tener mínimo 10 dígitos'
const loaderMessage = 'Consultando garantía'

const SearchReserveView = () => {
  const inputGuarantee = useRef()
  const navigate = useNavigate()
  const dispatch = useDispatch()
  const loading = useContext(LoaderContext)

  const [enableQuery, setEnableQuery] = useState(false)
  const inputStatus = useSelector((state) => state.bdbInputComponent._status)
  const msgError = useSelector((state) => state.bdbInputComponent.msgError)
  const { garaCodigo } = useSelector((state) => state.reserve.guaranteeInfo)

  const handleOnChange = useCallback(
    ({ detail }) => {
      if (inputStatus === 'ERROR')
      { dispatch(
        setInputParameters({ _status: 'ENABLED', msgError: lengthError })
      ) }
      setEnableQuery(detail.valid)
      if (detail.valid) dispatch(setGuaranteeNumer(detail.value))
    },
    [inputStatus, dispatch]
  )

  const handleClickQuery = () => {
    dispatch(queryGuarantee(loading))
  }

  useEffect(() => {
    const { current } = inputGuarantee
    const setListeners = () =>
      current.addEventListener('atInputChanged', handleOnChange)
    setListeners()

    return () => {
      current.removeEventListener('atInputChanged', handleOnChange)
    }
  }, [dispatch, handleOnChange])
  useEffect(() => {
    dispatch(setMessageLoader(loaderMessage))
    dispatch(setInputParameters({ _status: 'ENABLED', msgError: lengthError }))
    dispatch(setRouteBack('/home'))
    return () => {
      dispatch(resetLoader())
    }
  }, [dispatch])

  useEffect(() => {
    const _redirect = () => navigate(garaCodigo.toString())
    if (garaCodigo) _redirect()
  }, [garaCodigo, navigate])

  return (
    <div className="_flex-j-center col-lg-6 s-m-t-8 margin-auto-x">
      <div className="col-lg-8">
        <bdb-at-input
          data-cy="input-guarantee"
          name="input-guarantee"
          ref={inputGuarantee}
          label="Número de garantía"
          placeholder="Ingresa número de garantía"
          type="NUMBER"
          status={inputStatus}
          required="true"
          minlength="10"
          maxlength="24"
          message={msgError}
        ></bdb-at-input>
      </div>
      <div className="col-lg-3">
        <button
          data-cy="button-query"
          onClick={handleClickQuery}
          className="bdb-at-btn bdb-at-btn--primary bdb-at-btn--lg s-m-t-7"
          disabled={!enableQuery}
        >
          Consultar
        </button>
      </div>
    </div>
  )
}

export default SearchReserveView
