import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Outlet, useNavigate, useParams } from 'react-router'
import ManagerHeader from '../../components/HeaderComponent/ManagerHeader'
import { Sidebar } from '../../components/SidebarComponent/Sidebar'
import { setSelectedType } from '../../store/slices/caseManager/caseManagerSlice'
import { processes } from '../../constants'

const ManagerLayout = () => {
  const dispatch = useDispatch()
  const params = useParams()
  const { type } = params
  const navigate = useNavigate()
  const selectedType = useSelector((state) => state.casesManager.selectedType)
  useEffect(() => {
    if (params['*'].includes('reserve')) return

    if (!type && isEmpty(selectedType)) {
      navigate('/home')
      return
    }
    if (type && isEmpty(selectedType)) {
      const responseSelectedType = getSelectedType()
      if (!responseSelectedType) {
        navigate('/home')
        return
      }
      dispatch(setSelectedType(getSelectedType()))
    }
  }, [])
  const getSelectedType = () => {
    return processes.find((el) => el.type === type)
  }

  const isEmpty = (object) => {
    for (const property in object) {
      return false
    }
    return true
  }

  return (
    <>
      <div className="flex-row">
        {params['*'].includes('request-case') && <Sidebar />}
        <section className="_managerLayout-container-content">
          <ManagerHeader />
          <Outlet />
        </section>
      </div>
    </>
  )
}

export default ManagerLayout
