import React, {
  useCallback,
  useEffect,
  useState,
  useContext,
  useLayoutEffect,
  useRef
} from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useNavigate } from 'react-router'
import { LoaderContext } from '../../Contexts/LoaderContex'
import {
  transformDateStringToMoment,
  transformDateToMoment
} from '../../helpers/date'
import { validateObjectFieldsNotEmpty } from '../../helpers/validations'
import { resetToastParameters } from '../../store/slices/bdbToastComponent/bdbToastComponentSlice'
import {
  resetLoader,
  setMessageLoader,
  setRouteBack
} from '../../store/slices/loader/loaderSlice'
import { updateByKeyGuaranteeInfo } from '../../store/slices/reserve/reserveSlice'
import { requestReserveGuarantee } from '../../store/slices/reserve/reserveThunk'

const items = [
  { label: 'Nombre del cliente', attribute: 'nombre' },
  { label: 'Número de garantía', attribute: 'garaCodigo' },
  { label: 'Tipo y Número de documento', attribute: 'fullDocument' }
]

const termOptions = [
  { label: 'Días', value: 'd', isChecked: 'false' },
  { label: 'Meses', value: 'm', isChecked: 'false' }
]

const periodOptions = [
  { text: 'Única anticipada', value: '61' },
  { text: 'Anual anticipada', value: '60' }
]

const tooltipMsg =
  'Para plazo de obligación de 12 meses o menos, ' +
  'seleccionar en periodo cobro de comisión anual anticipada'

const mandatoryFields = [
  'nombre',
  'btiidCodigo',
  'clieCodigo',
  'garaCodigo',
  'termCommission',
  'unitTerm',
  'termObligation',
  'fechaCorteFiscal'
]
const loaderMessage = 'Consultando reserva'

const RequestReserveView = () => {
  const dispatch = useDispatch()
  const calendar = useRef()
  const dropdown = useRef()
  const inputTerm = useRef()
  const checkBox = useRef()
  const toast = useRef()
  const navigate = useNavigate()
  const loader = useContext(LoaderContext)

  const [checkBoxOptions] = useState(termOptions)
  const [enableRequestButton, setEnableRequestButton] = useState(false)

  const guaranteeInfo = useSelector((state) => state.reserve.guaranteeInfo)
  const { icon, msg } = useSelector((state) => state.bdbToastComponent)
  const { unitTerm, termObligation } = guaranteeInfo

  const handleOnChange = useCallback(
    (e) => {
      const id = e.target.id
      let value

      if (!id) return
      if (id === 'termObligation') value = e.detail.value
      if (id === 'termCommission') value = e.detail.value
      if (id === 'unitTerm')
      { value = e.detail.find((option) => option.isChecked === 'true').value }
      if (id === 'fechaCorteFiscal')
      { value = transformDateToMoment(e.detail[0]).format('YYYYMMDD') }
      dispatch(updateByKeyGuaranteeInfo({ key: id, value }))
    },
    [dispatch]
  )
  const handleRequestReserve = () => {
    dispatch(requestReserveGuarantee(loader))
  }

  useLayoutEffect(() => {
    calendar.current.minDate = new Date(2020, 0, 1)
    if (guaranteeInfo.fechaCorteFiscal) {
      calendar.current.value = [
        transformDateStringToMoment(guaranteeInfo.fechaCorteFiscal).toDate()
      ]
    }
  }, [guaranteeInfo])

  useEffect(() => {
    const _setDropdown = () => {
      let _value
      if (
        (unitTerm === 'm' && termObligation > 12) ||
        (unitTerm === 'd' && termObligation > 365)
      )
      { _value = periodOptions[0] }
      else _value = periodOptions[1]
      dropdown.current.setValue(_value.text)
      dispatch(
        updateByKeyGuaranteeInfo({ key: 'termCommission', value: _value.text })
      )
    }
    if (!!unitTerm && !!termObligation) _setDropdown()
  }, [unitTerm, termObligation, dispatch])

  useEffect(() => {
    const validateButton = () => {
      const isValid = validateObjectFieldsNotEmpty(
        guaranteeInfo,
        mandatoryFields
      )
      setEnableRequestButton(isValid)
    }
    validateButton()
    if (Object.keys(guaranteeInfo).length === 0) navigate('/reserve/search')
    if (guaranteeInfo.statusReserve) navigate('/reserve/confirmation')
  }, [guaranteeInfo, navigate])

  useEffect(() => {
    const { current: currentCalendar } = calendar
    const { current: currentInput } = inputTerm
    const { current: currentCheck } = checkBox
    const { current: currentDropDown } = dropdown
    const setListeners = () => {
      currentCalendar.addEventListener('liteCalendarChanged', handleOnChange)
      currentInput.addEventListener('atInputChanged', handleOnChange)
      currentCheck.addEventListener('radioEmitter', handleOnChange)
      currentDropDown.addEventListener('elementSelectedAtom', handleOnChange)
    }
    setListeners()

    return () => {
      currentCalendar.removeEventListener('liteCalendarChanged', handleOnChange)
      currentInput.removeEventListener('atInputChanged', handleOnChange)
      currentCheck.removeEventListener('radioEmitter', handleOnChange)
      currentDropDown.removeEventListener('elementSelectedAtom', handleOnChange)
    }
  }, [handleOnChange])

  useEffect(() => {
    if (!!icon && !!msg) toast.current.show()
  }, [icon, msg])

  useEffect(() => {
    dispatch(setMessageLoader(loaderMessage))
    dispatch(setRouteBack('/reserve/search'))
    return () => {
      dispatch(resetToastParameters())
      dispatch(resetLoader())
    }
  }, [dispatch])

  return (
    <div className="_requestReserve-card-container s-m-t-8">
      <bdb-at-toast
        ref={toast}
        type={icon}
        text-desc={msg}
        is-close="false"
        time={15000}
      ></bdb-at-toast>
      <div
        data-cy="tittle-reserve"
        className="sherpa-typography-heading-5 _requestReserve_tittle s-m-b-6"
      >
        Solicitar reserva
      </div>
      <div className="_flex-wrap">
        <div
          className="_requestReserve-col-info"
          style={{ paddingRight: '17%' }}
        >
          {items.map((item, idx) => (
            <div key={idx} className="s-m-b-4">
              <label className="sherpa-typography-label-1 _requestReserve_label">
                {item.label}
                <span className=" _requestReserve_text s-m-t-1">
                  {guaranteeInfo[item.attribute]}
                </span>
              </label>
            </div>
          ))}
        </div>
        <div className="_requestReserve-col-info">
          <div className="s-m-b-4">
            <bdb-ml-calendar-lite
              data-cy="date-calendar"
              ref={calendar}
              id="fechaCorteFiscal"
            ></bdb-ml-calendar-lite>
          </div>
          <div className="s-m-b-4">
            <bdb-at-dropdown
              id="termCommission"
              ref={dropdown}
              label="Período cobro de comisión"
              status="ENABLED"
              message=""
              placeholder="Selecciona"
              tooltip-message={tooltipMsg}
              tooltip="true"
              options={JSON.stringify(periodOptions)}
            ></bdb-at-dropdown>
          </div>
          <div className="s-m-b-4">
            <label className="sherpa-typography-label-1 _requestReserve_label">
              Plazo de la obligación
              <div className="_flex-d-flex s-m-t-2">
                <bdb-at-input
                  data-cy="termObligation"
                  id="termObligation"
                  ref={inputTerm}
                  label=""
                  placeholder="#"
                  type="NUMBER"
                  status="ENABLED"
                  required="true"
                  maxlength="3"
                  message="El plazo de la oligación debe ser mínimo de 1 dígito"
                ></bdb-at-input>
                <bdb-at-radio-button
                  data-cy="unitTerm"
                  id="unitTerm"
                  ref={checkBox}
                  is-horizontal="true"
                  values-to-radio={JSON.stringify(checkBoxOptions)}
                ></bdb-at-radio-button>
              </div>
            </label>
          </div>
        </div>
      </div>
      <button
        data-cy="btn-request"
        id="btn-request"
        className="bdb-at-btn bdb-at-btn--primary bdb-at-btn--lg"
        disabled={!enableRequestButton}
        onClick={handleRequestReserve}
      >
        Solicitar reserva
      </button>
    </div>
  )
}

export default RequestReserveView
