import * as CryptoJS from 'crypto-js'

export const encryptUsingAES256 = (value, iv) => {
  const _key = CryptoJS.enc.Utf8.parse(process.env.REACT_APP_KEY_AES)
  const _iv = CryptoJS.enc.Hex.parse(iv)
  return CryptoJS.AES.encrypt(
    value, _key, {
      keySize: 128,
      iv: _iv,
      mode: CryptoJS.mode.CBC,
      padding: CryptoJS.pad.Pkcs7
    }).toString()
}

export const decryptUsingAES256 = (value, iv)  => {
  const _key = CryptoJS.enc.Utf8.parse(process.env.REACT_APP_KEY_AES)
  const _iv = CryptoJS.enc.Hex.parse(iv)
  return CryptoJS.AES.decrypt(
    value, _key, {
      keySize: 128,
      iv: _iv,
      mode: CryptoJS.mode.CBC,
      padding: CryptoJS.pad.Pkcs7
    }).toString(CryptoJS.enc.Utf8)
}
