import React from 'react'
import { Storage, Hub, PubSub } from 'aws-amplify'
import PropTypes from 'prop-types'
import { CONNECTION_STATE_CHANGE, ConnectionState } from '@aws-amplify/pubsub'
import { IotCore } from '../../services/iotCore'
import {  getDocumenS3 } from '../../services/getDocumenS3'

const UploadFile = ({
  customNameFile,
  prefix,
  onFinishUpload,
  onProgressUpload,
  onErrorUpload,
  acceptFiles
}) => {
  const fetchRecentData = () => {
    //
  }
  const searchFile = async (file) => {
    const nameFile = customNameFile(file.name)
    const response = await getDocumenS3(prefix, nameFile).then(() => {
      return true
    }).catch(() => {
      return false
    })
    return response
  }

  let priorConnectionState
  Hub.listen('pubsub', (data) => {
    const { payload } = data
    console.log('STATE ', payload.data)
    if (
      payload.event === CONNECTION_STATE_CHANGE
    ) {
      if (priorConnectionState === ConnectionState.Connecting && payload.data.connectionState === ConnectionState.Connected) {
        fetchRecentData()
      }
      priorConnectionState = payload.data.connectionState
    }
  })

  const uploadFile = async (e) => {
    const file = e.target.files[0]
    const nameFile = customNameFile(file.name)
    const response = await searchFile(file)
    console.log('response =>', response)
    PubSub.subscribe('LOAD_FILE_STATE').subscribe({
      next: data => {
        console.log('Data', data)
        if (data.value.error === 'File Duplicated!') {
          return onErrorUpload({ message: 'repeatedName' })
        }
      },
      error: (error) => {
        console.error(error)
      },
      complete: () => console.log('Done')
    })
    if (response) {
      return onErrorUpload({ message: 'repeatedName' })
    }
    if (!acceptFiles.find((item) => file.name.includes(item))) {
      return onErrorUpload({ message: 'nameFormat' })
    }
    Storage.put(nameFile, file, {
      level: 'protected',
      serverSideEncryption: 'AES256',
      customPrefix: {
        public: `public/${prefix}`,
        protected: `protected/${prefix}`
      },
      resumable: true,
      completeCallback: (e) => {
        onFinishUpload(e, file.name)
        new IotCore().giveIotPermissionUserCognito()
      },
      progressCallback: onProgressUpload,
      errorCallback: onErrorUpload
    })
    e.target.value = null
  }

  return (
    <>
      <div className="bdb-ml-upload-file">
        <label
          className="bdb-at-btn bdb-at-btn--bdb-at-btn bdb-at-btn--link bdb-at-btn--sm--ico bdb-at-btn--lg--ico"
          htmlFor="file"
        >
          <span className="ico-document-upload"></span>Cargar archivo
        </label>
        <input
          data-cy="file-upload"
          id="file"
          type="file"
          accept=".csv"
          onChange={uploadFile}
          hidden={true}
          className="myfile-upload__input"
        />
      </div>
    </>
  )
}
UploadFile.propTypes = {
  customNameFile: PropTypes.func.isRequired,
  prefix: PropTypes.string,
  onFinishUpload: PropTypes.func.isRequired,
  onProgressUpload: PropTypes.func.isRequired,
  onErrorUpload: PropTypes.func.isRequired,
  acceptFiles: PropTypes.array
}
export default UploadFile
