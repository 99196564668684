import React, { useContext, useMemo } from 'react'
import { useDispatch } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import Header from '../../components/HeaderComponent/Header'
import { setSelectedType } from '../../store/slices/caseManager/caseManagerSlice'
import { processes, itemsSidebar } from '../../constants'
import { LoaderContext } from '../../Contexts/LoaderContex'
import { getLoginAdviser } from '../../services/loginAdviserService'

const MenuDisbursement = () => {
  const nameUser = useMemo(() => sessionStorage.getItem('nameUser')
    , [])
  const navigate = useNavigate()
  const dispatch = useDispatch()
  const loading = useContext(LoaderContext)

  const handleClick = (item) => {
    const { type } = item
    const prefixRedirect = type === 'reserve' ? '/' : '/request-case/'
    const process = itemsSidebar[type][0].slug
    const redirect = `${prefixRedirect}${type}/${process}`

    dispatch(setSelectedType(item))
    navigate(redirect)
  }

  const handleRedirectApp = () => {
    dispatch(getLoginAdviser(loading))
  }

  return (
    <>
      <Header />
      <div className="box-container">
        <div className="container-titel">
          <div className="container-subtitel">
            <p className="user-sub-titel label-1"> Hola,</p>
            <h2 className="user-name label-1">{nameUser}</h2>
          </div>
          <div className="daily-management">
            <button data-cy="redirect-app" onClick={() => handleRedirectApp()} className="bdb-at-btn bdb-at-btn--primary bdb-at-btn--lg">
              <span className="ico-calendar-default"></span>Gestión diaria
            </button>
          </div>
        </div>

        <div className="container-titel-tools">
          <p className="tool-name label-1">Herramientas en línea</p>
          <h3 className="name-process  sherpa-typography-heading-6">
            Desembolso centralizado
          </h3>
        </div>
        <div className="container-process-card">
          {processes.map((item) => (
            <div className="process-card" key={`${item.label}`}>
              <bdb-ml-access-card
                id-el={`${item.label}`}
                title-label={`${item.label}`}
                type-icon={`${item.icon}`}
                obfus-title="false"
                box-width="80%"
                box-height=" 57%"
                onClick={() => handleClick(item)}
                cardClicked={`${item.label}`}
                id={`${item.label}`}
              ></bdb-ml-access-card>
            </div>
          ))}
        </div>
      </div>
    </>
  )
}

export default MenuDisbursement
