import {
  getCasesByUser,
  updateCases,
  getCustomCasesByManagement,
  getCustomCasesByCovenance,
  getListCases,
  getCases
} from '../../../services/casesGraphql'
import {
  setOpenCase,
  getPausedCases,
  getOpenCases,
  setCountCases,
  setListCases,
  setCaseInfo,
  setCaseFoundError,
  setSuccessUpdate
} from './caseManagerSlice'
import {
  mapCasesToDataTable,
  mapCountStatusToDataTable,
  mapProductivityAutomatic,
  mapTotalByStatusToDataTable,
  mapUpdateCase
} from '../../../helpers/mapper'
import { getHeaders } from '../../../helpers/utils'

import { setSkeleton } from '../loader/loaderSlice'
import moment from 'moment'
import { v4 as uuidv4 } from 'uuid'
import { API, Auth } from 'aws-amplify'
import { CasesStates, itemsSidebar } from '../../../constants'
import { ModalConfig } from '../../../components/CaseComponent/ModalConfig'
import { setModalParameters } from '../bdbModalComponent/bdbModalSlice'

export const getCasePending = (loading, toast) => {
  return async (dispatch, getState) => {
    const { process, label } = getState().casesManager.managerProcess
    const payload = {
      management: 'Gcia. Op. Desembolsos Integrales',
      process,
      reqStatus: CasesStates.PENDING,
      reqUser: sessionStorage.getItem('user'),
      beginDate: moment().format('YYYY-MM-DDTHH:mm:ss')
    }
    loading.openLoader()
    const isVoBo = label === 'Libranza VoBo Pagaduría'
    const getCaseFunction = isVoBo
      ? getCustomCasesByCovenance
      : getCustomCasesByManagement
    const nameProperty = isVoBo
      ? 'getCustomByCovenance'
      : 'getCustomByManagement'
    await getCaseFunction(payload)
      .then((resp) => {
        const response = resp.data[nameProperty]
        if (response.length > 0) dispatch(setOpenCase(response[0]))
        else toast.show()
      })
      .catch((err) => console.error(err))
      .finally(() => loading.closeLoader())
  }
}

export const getCasesByStatus = (status, user, loading, type) => {
  return async (dispatch) => {
    loading.openLoader()
    await getCasesByUser({
      reqUser: user,
      reqStatusCreatedAt: { beginsWith: { reqStatus: status } }
      // limit: 1
    })
      .then((resp) => {
        const casePausedTable = []
        const caseOpenTable = []
        const response = resp.data.casesByUser.items
        for (const element in response) {
          const paused = response[element]
          if (response[element].reqStatus === CasesStates.PAUSED) {
            const belongsType = itemsSidebar[type].find(option => option.process === paused.process)
            if (belongsType) casePausedTable.push(paused)
          }
          if (response[element].reqStatus === CasesStates.OPEN) {
            caseOpenTable.push(paused)
          }
        }
        if (status === CasesStates.PAUSED) dispatch(getPausedCases(casePausedTable))
        if (status === CasesStates.OPEN) dispatch(getOpenCases(caseOpenTable))
      })
      .catch((err) => {
        console.error(err)
      })
      .finally(() => {
        loading.closeLoader()
      })
  }
}

export const requestNumberCase = (data) => {
  return async (dispatch) => {
    /*
    await getCases({
      requestNumber: data,
    })
      .then((resp) => {
        console.log(`hu-2512 => Numero de caso ${data}`, resp.data.listCases);
        dispatch(setGetCaseNumber(resp.data.listCases.items[0]));
      })
      .catch((err) => {
        console.error(err);
      })
      .finally(() => {
        console.log("Finished");
      });
      */
  }
}
export const updateCase = (_status, dataCase, loading) => {
  return async (dispatch, getState) => {
    const uuidP = uuidv4()
    const input = mapUpdateCase(_status, uuidP, dataCase)
    loading.openLoader()
    await updateCases({ input })
      .then(async (resp) => {
        dispatch(setOpenCase(dataCase))
      })
      .catch((err) => {
        console.error(err)
      })
      .finally(() => {
        loading.closeLoader()
      })
  }
}
export const updateCaseWithProductivity = (_status, productsNumber, loading, modal) => {
  return async (dispatch, getState) => {
    const success = {
      Aprobar: 'setApprovedSuccess',
      Devolver: 'setReturnedSuccess',
      Pausar: 'setPausedSuccess'
    }
    const errors = {
      Aprobar: 'setApprovedError',
      Devolver: 'setReturnedError',
      Pausar: 'setPausedError'
    }
    const { openCase } = getState().casesManager
    const { textAreaValue, dropDownValue } = getState().bdbModalComponent
    const uuidP = uuidv4()
    const input = mapUpdateCase(_status, uuidP, { ...openCase, textAreaValue, dropDownValue }, productsNumber)
    const modalParameters = new ModalConfig(openCase.requestNumber)
    loading.openLoader()
    await updateCases({ input })
      .then(async (resp) => {
        modalParameters.setSuccess()[success[_status]]()
        const resultPost = await postAutomaticProductivity(_status, uuidP, productsNumber, openCase)
        if (resultPost?.error) modalParameters.addPostError(resultPost.error)
      })
      .catch((err) => {
        modalParameters.setError()[errors[_status]]()
        console.error(err)
      })
      .finally(() => {
        dispatch(setModalParameters(modalParameters.getObject()))
        loading.closeLoader()
        modal.openAlert()
      })
  }
}

export const getCountCasesByStatus = (
  management,
  objectProcesses,
  dateInit,
  dateEnd
) => {
  return async (dispatch) => {
    const groups = Object.keys(objectProcesses)
    dispatch(setSkeleton(true))
    for (const group of groups) {
      let countArray = []
      await Promise.all(
        objectProcesses[group].map(({ process: _process, label }, idx) =>
          getListCases({
            aggregates: {
              field: 'reqStatus',
              type: 'terms',
              name: 'reqStatus'
            },
            filter: {
              management: { eq: management },
              process: { eq: _process },
              createdAt: { gte: dateInit, lte: dateEnd }
            }
          })
            .then((resp) => {
              const count =
                resp.data.searchCases.aggregateItems[0].result.buckets
              countArray = [
                ...countArray,
                mapCountStatusToDataTable(label, count, idx)
              ]
              countArray.sort((a, b) => (a.id > b.id ? 1 : -1))
              const countProcess = { [group]: countArray }
              dispatch(setCountCases(countProcess))
            })
            .catch((err) => {
              console.error(err)
            })
        )
      ).finally(() => {
        const [totalCount, countObject] =
          mapTotalByStatusToDataTable(countArray)
        dispatch(setCountCases({ [group]: [...countArray, countObject] }))
        dispatch(setCountCases({ [`${group}#Total`]: totalCount }))
      })
    }
    dispatch(setSkeleton(false))
  }
}

export const getCasesByRequestNumber = (data, loader) => {
  return async (dispatch) => {
    if (loader) loader.openLoader()
    await getCases({
      requestNumber: data
    })
      .then((resp) => {
        if (resp.data.listCases.items.length !== 0) {
          dispatch(setCaseInfo(resp.data.listCases.items[0]))
          const mapperResponse = mapCasesToDataTable(resp.data.listCases.items)
          dispatch(setListCases(mapperResponse))
          dispatch(setCaseFoundError(false))
        } else {
          dispatch(setCaseFoundError(true))
          dispatch(setListCases([]))
          dispatch(setCaseInfo({}))
        }
      })
      .catch((err) => {
        dispatch(setCaseFoundError(true))
        dispatch(setListCases([]))
        dispatch(setCaseInfo({}))
        console.error(err)
      })
      .finally(() => {
        if (loader) loader.closeLoader()
      })
  }
}
export const updateCaseFields = (input, navigate, path, loading) => {
  return async (dispatch, getState) => {
    loading.openLoader()
    await updateCases({ input })
      .then(async (resp) => {
        const data = [{ ...resp.data.updateCase }]
        const mapperResponse = mapCasesToDataTable(data)
        const { cases } = getState().casesManager
        const casesData = cases.map((item) => {
          if (JSON.parse(item.original).id === input.id) {
            return mapperResponse[0]
          }
          return item
        })
        dispatch(setListCases(casesData))
        dispatch(getCasesByRequestNumber(input.requestNumber))
        console.log(resp.data.updateCase)
        dispatch(setSuccessUpdate(true))
        navigate(path)
      })
      .catch((err) => {
        dispatch(setSuccessUpdate(false))
        loading.closeLoader()
        console.error(err)
      })
      .finally(() => {
        loading.closeLoader()
      })
  }
}
const getInfoStaff = async () => {
  const path = 'control-board/staff'
  const number = sessionStorage.getItem('documentUser')
  const vertical = 'Dirección del Activo'
  return await API.get('SelfAssign', path, {
    queryStringParameters: {
      number,
      vertical
    }
  })
}

const getInfoProcess = async (vertical, management, area, processName) => {
  const path = `control-board/config/processes/${encodeURIComponent(vertical)}/${encodeURIComponent(management)}/${encodeURIComponent(area)}/${encodeURIComponent(processName)}`
  return await API.get('SelfAssign', path)
}

export const postTask = async (data) => {
  const accessToken = (await Auth.currentSession())
    .getIdToken()
    .getJwtToken()
  const headers = await getHeaders()
  return await API.post('microservice', 'operations/active/tasks', {
    body: data,
    headers: {
      Authorization: accessToken,
      ...headers
    }
  })
}

export const postAutomaticProductivity = async (_status, uuidP, productsNumber, openCase) => {
  const infoStaff = await getInfoStaff()
    .then((resp) => resp.Items[0])
    .catch((err) => {
      console.error(err)
      return { error: 'Staff' }
    })
  if (infoStaff?.error) return infoStaff
  const infoProcess = await getInfoProcess('Dirección del Activo', openCase.management, openCase.area, openCase.process)
    .then((resp) => resp.Items)
    .catch((err) => {
      console.error(err)
      return { error: 'Process' }
    })
  if (infoProcess?.error) return infoProcess
  const resultPost = await postTask(mapProductivityAutomatic(_status, uuidP, productsNumber, openCase, infoStaff, infoProcess))
    .then(() => {})
    .catch((err) => {
      console.error(err)
      return { error: 'PostTask' }
    })
  if (resultPost?.error) return resultPost
}
