import { createSlice } from '@reduxjs/toolkit'

const initialState = {
  icon: 'reset',
  msg: null
}

export const bdbToastComponentSlice = createSlice({
  name: 'BDB_TOAST_COMPONENT',
  initialState,
  reducers: {
    setToastParameters: (state, action) => {
      state.icon = action.payload.icon
      state.msg = action.payload.msg
    },
    resetToastParameters: (state) => {
      state.icon = initialState.icon
      state.msg = initialState.msg
    }
  }
})

// Action creators are generated for each case reducer function
export const { setToastParameters, resetToastParameters } =
  bdbToastComponentSlice.actions

export default bdbToastComponentSlice.reducer
