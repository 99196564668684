import React, { useEffect, useRef, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { friendlyStates, itemsSidebar } from '../../constants'
import { getDayNow } from '../../helpers/date'
import { setSelectedType } from '../../store/slices/caseManager/caseManagerSlice'
import { getCountCasesByStatus } from '../../store/slices/caseManager/caseManagerThunks'
import Skeleton from './Skeleton'

const tabOptions = [
  {
    id: '1',
    title: 'Procesos Desembolsos',
    disabled: false,
    group: 'groupDisbursement'
  },
  {
    id: '2',
    title: 'Procesos Revisión Documental',
    disabled: false,
    group: 'reviewDoc'
  }
]
const processTable = [
  { colName: '', control: 'id' },
  { colName: 'Proceso', control: 'text' },
  { colName: 'Aprobados', control: 'text' },
  { colName: 'Devueltos', control: 'text' },
  { colName: 'Pausados', control: 'text' },
  { colName: 'Asignados', control: 'text' },
  { colName: 'Pendientes', control: 'text' }
]

const processesDisbursement = {
  groupDisbursement: itemsSidebar.groupDisbursement,
  reviewDoc: itemsSidebar.reviewDoc
}

const IndicatorsLeader = () => {
  const tab = useRef()
  const dispatch = useDispatch()

  const countCases = useSelector((state) => state.casesManager.countCases)
  const skeletonState = useSelector((state) => state.loader.skeletonState)
  const [group, setGroup] = useState('groupDisbursement')

  const handleClick = ({ detail }) => {
    setGroup(detail.group)
  }
  useEffect(() => {
    dispatch(setSelectedType({ label: 'Indicadores desembolso centralizado' }))
    dispatch(
      getCountCasesByStatus(
        'Gcia. Op. Desembolsos Integrales',
        processesDisbursement,
        getDayNow().set('date', 1).format('YYYY-MM-DD'),
        getDayNow().format('YYYY-MM-DD')
      )
    )
  }, [dispatch])

  useEffect(() => {
    const { current } = tab
    const _listeners = () => {
      current.addEventListener('mlTabsChanged', handleClick)
    }
    if (current) _listeners()

    return () => {
      if (current) current.removeEventListener('mlTabsChanged', handleClick)
    }
  }, [skeletonState])

  return (
    <div>
      {!skeletonState
        ? (
        <>
          <div className="_indicatorsLeader-card-container s-p-x-6 s-m-t-8">
            <bdb-at-avatar
              size="md"
              icon-class="ico-money-bag"
              enable="true"
            ></bdb-at-avatar>
            <span
              data-cy="tittle"
              className="sherpa-typography-title-2 col-lg-3"
            >
              Total casos {getDayNow().format('MMMM')}
            </span>
            <span className="s-m-x-6">|</span>
            <div className="_indicatorsLeader-box-totals">
              <div className="_indicatorsLeader-col">
                <label className="sherpa-typography-label-1">
                  Desembolso:
                  <span data-cy="total-count-0">
                    {countCases['groupDisbursement#Total']}
                  </span>
                </label>
              </div>
              <div className="_indicatorsLeader-col">
                <label className="sherpa-typography-label-1">
                  Revisión documental:
                  <span data-cy="total-count-1">
                    {countCases['reviewDoc#Total']}
                  </span>
                </label>
              </div>
            </div>
          </div>
          <div className="s-m-t-10">
            <bdb-ml-secondtabs
              id="tab"
              ref={tab}
              position="left"
              proportional-width="false"
              tabs={JSON.stringify(tabOptions)}
            ></bdb-ml-secondtabs>
          </div>
          <div className="s-m-t-4">
            <bdb-ml-dynamic-table
              data-cy="bdb-ml-dynamic-table"
              column-table={JSON.stringify(processTable)}
              row-table={JSON.stringify(countCases[group])}
            ></bdb-ml-dynamic-table>
          </div>
        </>
          )
        : (
        <Skeleton
          numberCols={Object.keys(friendlyStates).length + 1}
          numerRows={processesDisbursement.groupDisbursement.length + 2}
        />
          )}
      <div style={{ width: '114px' }} className="margin-auto-x">
        <a
          href={process.env.REACT_APP_GRAFANA_ENDPOINT}
          target="_blank"
          rel="noopener noreferrer"
        >
          <button className="bdb-at-btn bdb-at-btn--primary bdb-at-btn--sm s-m-t-4">
            Ver detalle<span>{'>'}</span>
          </button>
        </a>
      </div>
    </div>
  )
}

export default IndicatorsLeader
