import React from 'react'
import { Routes, Route } from 'react-router-dom'
import Case from '../components/CaseComponent/Case'
import AnalystView from '../components/AnalystComponent/Analyst'
import ManagerLayout from '../pages/ManagerLayout/ManagerLayout'
import PreviewDocs from '../pages/PreviewDocs/PreviewDocs'
import SearchReserveView from '../pages/ReserveFlow/SearchReserveView'
import RequestReserveView from '../pages/ReserveFlow/RequestReserveView'
import ConfimationReserveView from '../pages/ReserveFlow/ConfimationReserveView'

export const ManagerRoutes = () => {
  return (
    <Routes>
      <Route path="/request-case" element={<ManagerLayout />}>
        <Route path="/request-case/*" index element={<AnalystView />} />
        <Route path="/request-case/:type/:process" element={<AnalystView />} />
        <Route path="/request-case/:type/:process/:id" element={<Case />} />
      </Route>
      <Route path="/order-docs" element={<PreviewDocs />} />
      <Route path="/reserve" element={<ManagerLayout />}>
        <Route path="/reserve/search" index element={<SearchReserveView />} />
        <Route path="/reserve/search/:id" element={<RequestReserveView />} />
        <Route
          path="/reserve/confirmation"
          element={<ConfimationReserveView />}
        />
      </Route>
    </Routes>
  )
}
