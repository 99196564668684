import { createSlice } from '@reduxjs/toolkit'

const initialState = {
  countDown: false,
  logout: false,
  logint: false,
  msgError: ''
}

export const sessionSlice = createSlice({
  name: 'SESSION',
  initialState,
  reducers: {
    startCountDown: (state, action) => {
      state.countDown = action.payload
    },
    startCloseSession: (state, action) => {
      state.logout = action.payload
    },
    startLogin: (state, action) => {
      state.logint = action.payload
    },
    setMsgError: (state, action) => {
      state.msgError = action.payload
    }
  }
})

export const {
  startCountDown,
  startCloseSession,
  startLogin,
  setMsgError
} = sessionSlice.actions

export default sessionSlice.reducer
