import { createSlice } from '@reduxjs/toolkit'

const initialState = {
  message: 'Espera un momento por favor.',
  routeBack: null,
  skeletonState: false
}

export const loaderSlice = createSlice({
  name: 'LOADER',
  initialState,
  reducers: {
    setMessageLoader: (state, action) => {
      state.message = action.payload
    },
    resetLoader: (state) => {
      state.message = initialState.message
    },
    setRouteBack: (state, action) => {
      state.routeBack = action.payload
    },
    resetRouteBack: (state) => {
      state.routeBack = initialState.routeBack
    },
    setSkeleton: (state, action) => {
      state.skeletonState = action.payload
    }
  }
})

// Action creators are generated for each case reducer function
export const {
  setMessageLoader,
  resetLoader,
  setRouteBack,
  resetRouteBack,
  setSkeleton
} = loaderSlice.actions

export default loaderSlice.reducer
