import { Auth } from 'aws-amplify'
import AWS from 'aws-sdk/dist/aws-sdk-react-native'
import { AWS_REGION } from '../constants'

export const getDocumenS3 = async (prefix, nameFile) => {
  await Auth.currentCredentials()
  const identityId =  await Auth.Credentials?._credentials?.identityId
  const options = {
    region: AWS_REGION,
    ...Auth.Credentials._credentials
  }
  const s3 = new AWS.S3(options)
  const params = {
    Bucket: `${process.env.REACT_APP_BUCKET}${prefix}${identityId}`,
    Key: `${nameFile}`
  }
  console.log('params =>', params)
  return new Promise((resolve, reject) => {
    try {
      s3.headObject(params, (err, data) => {
        if (err) {
          console.log('err', err)
          reject(err)
        }
        else resolve(data)
      })
    } catch (error) {
      console.log('erro =>', error)
    }
  })
}
