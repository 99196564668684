import { API } from 'aws-amplify'
import { mapReserveRequest, mapScgQueryResponse } from '../../../helpers/mapper'
import { discoverStatusReserve } from '../../../helpers/validations'
import { setInputParameters } from '../bdbInputComponent/bdbInputComponentSlice'
import {
  resetToastParameters,
  setToastParameters
} from '../bdbToastComponent/bdbToastComponentSlice'
import { setGuaranteeInfo } from './reserveSlice'

const notFoundError = 'El número de garantía no existe'
const fieldsScgInclude = [
  'nombre',
  'btiidCodigo',
  'clieCodigo',
  'garaCodigo',
  'fechaCorteFiscal'
]
const fieldsReserveInclude = [
  'nombre',
  'btiidCodigo',
  'clieCodigo',
  'garaCodigo',
  'efngCodigo',
  'prfnCodigo',
  'valorGarantiaOriginal',
  'reservaCodigo',
  'fechaReserva',
  'observaciones'
]

export const queryGuarantee = (loading) => {
  return async (dispatch, getState) => {
    const guaranteeNumber = getState().reserve.guaranteeNumber
    loading.openLoader()

    await API.get('Guarantee', `scg/${guaranteeNumber}`)
      .then((resp) => {
        console.log('Response API', resp)
        const object = resp.object
        const dataInfo = mapScgQueryResponse(
          [
            { ...object.clientInfo },
            { ...object.guaranteeExtraInfo },
            { ...object.guaranteeInfo }
          ],
          fieldsScgInclude
        )
        dispatch(setGuaranteeInfo({ ...dataInfo }))
      })
      .catch((err) => {
        console.error(err)
        if (err.response.status === 404) {
          dispatch(
            setInputParameters({ _status: 'ERROR', msgError: notFoundError })
          )
        }
      })
      .finally(() => loading.closeLoader())
  }
}

export const requestReserveGuarantee = (loading) => {
  return async (dispatch, getState) => {
    const guaranteeInfo = getState().reserve.guaranteeInfo
    dispatch(resetToastParameters())
    loading.openLoader()

    await API.post('Guarantee', 'fng/reserve', {
      body: mapReserveRequest(guaranteeInfo)
    })
      .then((resp) => {
        console.log('Response API', resp)

        const { object, productReplaced, replacedFNGProduct } = resp.data

        const dataInfo = mapScgQueryResponse(
          [
            { ...object.clientInfo },
            { ...object.guaranteeExtraInfo },
            { ...object.guaranteeInfo }
          ],
          fieldsReserveInclude
        )
        const statusReserve = discoverStatusReserve(
          object.guaranteeInfo.efngCodigo,
          productReplaced,
          replacedFNGProduct
        )
        dispatch(setGuaranteeInfo({ ...dataInfo, statusReserve }))
      })
      .catch((err) => {
        console.error(err)
        dispatch(
          setToastParameters({
            icon: 'ERROR',
            msg: err.response.data
          })
        )
      })
      .finally(() => loading.closeLoader())
  }
}
