import { Auth } from 'aws-amplify'
import AWS from 'aws-sdk/dist/aws-sdk-react-native'
import { AWS_REGION } from '../constants'
const mqttLeadersPolicy = 'mqttLeadersOperactionsActive'

export class IotCore {
  constructor () {
    Auth.currentCredentials()
    this.iot = new AWS.Iot({
      apiVersion: '2015-05-28',
      region: AWS_REGION,
      ...Auth.Credentials._credentials
    })
  }

  async listTargetsForPolicy (policyName, marker) {
    const params = {
      policyName
    }
    if (marker) params.marker = marker
    return new Promise((resolve, reject) => {
      this.iot.listTargetsForPolicy(params, function (err, data) {
        if (err) {
          console.log(err, err.stack)
          reject(err)
        }
        else resolve(data)
      })
    })
  }

  attachPolicy (policyName, target, callback) {
    this.iot.attachPolicy({
      policyName,
      target
    }, function (err, data) {
      if (err) {
        console.log(err, err.stack)
        callback(null, err)
      }
      else callback(data, null)
    })
  }

  async giveIotPermissionUserCognito () {
    let marker
    let isTargetFound = false
    const identityId = Auth.Credentials?._credentials?.identityId
    if (!identityId) return console.error('MISSING CREDENTIALS')
    do {
      await this.listTargetsForPolicy(mqttLeadersPolicy, marker).then((data) => {
        isTargetFound = data.targets.some((target) => target.includes(identityId))
        marker = data.nextMarker
      }).catch((err) => console.error(err))
    } while (marker && !isTargetFound)
    console.log('identityId', identityId)
    if (isTargetFound) return console.log('identityId found')
    this.attachPolicy(mqttLeadersPolicy, identityId, (resp, err) => {
      if (err) console.error(err)
      else console.log('target attached')
    })
  }
}
