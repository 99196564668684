import { API, graphqlOperation } from 'aws-amplify'
import { getCovenance, listCovenances } from '../graphql/Cases/queries'
import {
  createCovenance,
  updateCovenance,
  deleteCovenance
} from '../graphql/Cases/mutations'

export const getCovenances = async (configurations) => {
  return await API.graphql(graphqlOperation(listCovenances, configurations))
}

export const getCovenanceById = async (configurations) => {
  return await API.graphql(graphqlOperation(getCovenance, configurations))
}

export const storeCovenance = async (configurations) => {
  return await API.graphql(graphqlOperation(createCovenance, configurations))
}
export const updateCovenances = async (configurations) => {
  return await API.graphql(graphqlOperation(updateCovenance, configurations))
}
export const destroyCovenance = async (configurations) => {
  return await API.graphql(graphqlOperation(deleteCovenance, configurations))
}
