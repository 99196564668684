import { API, graphqlOperation } from 'aws-amplify'
import {
  casesByMangament,
  casesByUser,
  getCustomByManagement,
  getCustomByCovenance,
  listCases,
  searchCases
} from '../graphql/Cases/queries'
import { updateCase } from '../graphql/Cases/mutations'

export const getCases = async (configurations) => {
  return await API.graphql(graphqlOperation(listCases, configurations))
}

export const getCustomCasesByManagement = async (configurations) => {
  return await API.graphql(
    graphqlOperation(getCustomByManagement, configurations)
  )
}

export const getCustomCasesByCovenance = async (configurations) => {
  return await API.graphql(
    graphqlOperation(getCustomByCovenance, configurations)
  )
}

export const getCasesByManagement = async (configurations) => {
  return await API.graphql(graphqlOperation(casesByMangament, configurations))
}

export const getCasesByUser = async (configurations) => {
  return await API.graphql(graphqlOperation(casesByUser, configurations))
}

export const updateCases = async (configurations) => {
  return await API.graphql(graphqlOperation(updateCase, configurations))
}

export const getListCases = async (configurations) => {
  return await API.graphql(graphqlOperation(searchCases, configurations))
}
