import React, { Fragment, useCallback, useEffect, useRef } from 'react'
import PropTypes from 'prop-types'
import CompoundModal from '../CompoundModal/CompoundModal'
import { useDispatch, useSelector } from 'react-redux'
import { setDropDownValue } from '../../store/slices/bdbModalComponent/bdbModalSlice'

const DropDownModal = ({ refCustom, confirmationClick, options }) => {
  const dropDown = useRef()
  const dispatch = useDispatch()
  const { title, dropDownValue } = useSelector((state) => state.bdbModalComponent)

  const handleClickCancel = (e) => {
    refCustom.current.closeBackdrop()
  }
  const handleClickOption = useCallback(
    (e) => {
      dispatch(setDropDownValue(e.detail?.value))
    },
    [dispatch]
  )

  useEffect(() => {
    const { current } = dropDown
    current.addEventListener('elementSelectedAtom', handleClickOption)

    return () => {
      current.removeEventListener('elementSelectedAtom', handleClickOption)
    }
  }, [handleClickOption])
  return (
    <Fragment>
        <CompoundModal
            backdrop={refCustom}
        >
            <div className='_dropDwonModal-box'>
                <span className="ico-information s-m-b-6" style={{ display: 'block' }}>
                    <span className="path1"></span>
                    <span className="path2"></span>
                    <span className="path3"></span></span>
                <span className='sherpa-typography-heading-6 s-m-b-8' style={{ display: 'block' }}>{title}</span>
                <div style={{ width: '80%' }}>
                    <bdb-at-dropdown
                        ref={dropDown}
                        name="1"
                        status="ENABLED"
                        placeholder="Selecciona la causal"
                        options={JSON.stringify(options)}
                    ></bdb-at-dropdown>
                </div>
                <div
                    className='_flex-justify-end s-m-t-7'
                    style={{ gap: '16px' }}
                >
                    <button
                        style={{ width: '152px' }}
                        onClick={confirmationClick}
                        className="bdb-at-btn bdb-at-btn--primary bdb-at-btn--lg"
                        disabled={!dropDownValue}
                    >
                        Pausar
                    </button>
                    <button
                        style={{ width: '152px' }}
                        onClick={handleClickCancel}
                        className="bdb-at-btn bdb-at-btn--secondary bdb-at-btn--lg"
                    >
                        Cancelar
                    </button>
                </div>
            </div>
        </CompoundModal>
    </Fragment>)
}

DropDownModal.propTypes = {
  refCustom: PropTypes.object.isRequired,
  options: PropTypes.array.isRequired,
  confirmationClick: PropTypes.func
}

export default DropDownModal
