/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const createCase = /* GraphQL */ `
  mutation CreateCase(
    $input: CreateCaseInput!
    $condition: ModelCaseConditionInput
  ) {
    createCase(input: $input, condition: $condition) {
      id
      currentProcess
      requestNumber
      typeDocument
      document
      file
      flow
      dateAssignment
      hourAssignment
      amountApproved
      management
      process
      reqStatus
      fileOrder
      priority
      reqUser
      line
      product
      assignedTo
      comercialProduct
      hasLibrance
      libranzaCode
      step
      seenGoodPagaduria
      createdAt
      updatedAt
      leaderOwner
      numberControl
      creditProject
      reasonReturn
      requestedProducts
      area
      idProductivity
      beginDate
      endDate
      owner
    }
  }
`;
export const updateCase = /* GraphQL */ `
  mutation UpdateCase(
    $input: UpdateCaseInput!
    $condition: ModelCaseConditionInput
  ) {
    updateCase(input: $input, condition: $condition) {
      id
      currentProcess
      requestNumber
      typeDocument
      document
      file
      flow
      dateAssignment
      hourAssignment
      amountApproved
      management
      process
      reqStatus
      fileOrder
      priority
      reqUser
      line
      product
      assignedTo
      comercialProduct
      hasLibrance
      libranzaCode
      step
      seenGoodPagaduria
      createdAt
      updatedAt
      leaderOwner
      numberControl
      creditProject
      reasonReturn
      requestedProducts
      area
      idProductivity
      beginDate
      endDate
      owner
    }
  }
`;
export const deleteCase = /* GraphQL */ `
  mutation DeleteCase(
    $input: DeleteCaseInput!
    $condition: ModelCaseConditionInput
  ) {
    deleteCase(input: $input, condition: $condition) {
      id
      currentProcess
      requestNumber
      typeDocument
      document
      file
      flow
      dateAssignment
      hourAssignment
      amountApproved
      management
      process
      reqStatus
      fileOrder
      priority
      reqUser
      line
      product
      assignedTo
      comercialProduct
      hasLibrance
      libranzaCode
      step
      seenGoodPagaduria
      createdAt
      updatedAt
      leaderOwner
      numberControl
      creditProject
      reasonReturn
      requestedProducts
      area
      idProductivity
      beginDate
      endDate
      owner
    }
  }
`;
export const createCovenance = /* GraphQL */ `
  mutation CreateCovenance(
    $input: CreateCovenanceInput!
    $condition: ModelCovenanceConditionInput
  ) {
    createCovenance(input: $input, condition: $condition) {
      id
      code
      name
      status
      users
      createdAt
      updatedAt
    }
  }
`;
export const updateCovenance = /* GraphQL */ `
  mutation UpdateCovenance(
    $input: UpdateCovenanceInput!
    $condition: ModelCovenanceConditionInput
  ) {
    updateCovenance(input: $input, condition: $condition) {
      id
      code
      name
      status
      users
      createdAt
      updatedAt
    }
  }
`;
export const deleteCovenance = /* GraphQL */ `
  mutation DeleteCovenance(
    $input: DeleteCovenanceInput!
    $condition: ModelCovenanceConditionInput
  ) {
    deleteCovenance(input: $input, condition: $condition) {
      id
      code
      name
      status
      users
      createdAt
      updatedAt
    }
  }
`;