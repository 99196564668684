import { getItemSidebarFromProcess } from '../../helpers/mapper'

const requestNumberRow = { label: 'Caso', atrribute: 'requestNumber' }
const controlNumberRow = { label: 'Nº Control', atrribute: 'numberControl' }
const genericBody = [
  { label: 'Documento cliente', atrribute: 'document' },
  { label: 'Fecha recibido', atrribute: 'dateAssignment' },
  { label: 'Hora recibido', atrribute: 'hourAssignment' }
]
const orderBody = [
  { label: 'Documento cliente', atrribute: 'document' },
  { label: 'Proyecto crediticio', atrribute: 'creditProject' },
  { label: 'Fecha recibido', atrribute: 'dateAssignment' },
  { label: 'Hora recibido', atrribute: 'hourAssignment' }
]

const oderPausedModal = {
  options: [
    {
      text: 'Inconsistencia en documentos',
      value: 'Inconsistencia en documentos'
    },
    { text: 'Bloqueo en aplicativos', value: 'Bloqueo en aplicativos' }
  ]
}

class CaseConfigs {
  constructor (
    mainTittle,
    bodyCase,
    hasDocuments,
    hasProducts,
    elementsSubTittle,
    pausedModal,
    option
  ) {
    this.mainTittle = mainTittle
    this.bodyCase = bodyCase
    this.hasDocuments = hasDocuments
    this.hasProducts = hasProducts
    this.elementsSubTittle = elementsSubTittle
    this.pausedModal = pausedModal
    this.optionSidebar = option
  }

  setSubTittle () {
    this.subTittle = this.elementsSubTittle.join(' - ')
    return this
  }
}

export const CaseConfigsFactory = (
  { product, currentProcess, line, file, process: caseProcess }
) => {
  const itemSideBar = getItemSidebarFromProcess(caseProcess)
  const slug = itemSideBar?.item?.slug
  switch (slug) {
    case 'order-disbursement':
      return new CaseConfigs(
        controlNumberRow,
        orderBody,
        true,
        false,
        [product, currentProcess],
        oderPausedModal,
        itemSideBar
      )
    case 'consumer':
      return new CaseConfigs(requestNumberRow, genericBody, false, true, [
        line,
        currentProcess,
        product
      ], null, itemSideBar)
    case 'captura-vivienda':
      return new CaseConfigs(requestNumberRow, genericBody, false, false, [
        currentProcess
      ], null, itemSideBar)
    default:
  }
  switch (file) {
    case 'WS':
      return new CaseConfigs(requestNumberRow, genericBody, false, true, [
        line,
        currentProcess,
        product
      ], null, itemSideBar)
    default:
      return new CaseConfigs(requestNumberRow, genericBody, false, true, [
        currentProcess
      ], null, itemSideBar)
  }
}
